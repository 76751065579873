import { useEffect, useState } from "react";

import Highcharts, { chart } from "highcharts";
import ChartDashboard from "../../../../../components/ChartDashboard/ChartDashboard.jsx";

import { useTheme } from "../../../../../utils/ThemeManager.jsx";
import { getChartColors } from "../../../../../utils/chartUtils.js";

import { formatCurrency } from "../../../../../utils/tableUtils.js";

import "./Branded.css";

Highcharts.themeDark = {
  colors: getChartColors("dark"),
};

Highcharts.themeLight = {
  colors: getChartColors("light"),
};

Highcharts.setOptions({
  plotOptions: {
    series: {
      animation: false,
    },
  },
});

export const isValidData = (data, requiredProperties) => {
  return requiredProperties.every(prop => {
    const keys = prop.split('.');
    let value = data;
    for (const key of keys) {
      if (!value || !value.hasOwnProperty(key)) {
        return false;
      }
      value = value[key];
    }
    return true;
  });
};

const requiredProperties = [
  "Uncategorized - Branded.total_annual_revenue",
  "Uncategorized - Branded.total_annual_profit",
  "Branded Side Effects.total_annual_revenue",
  "Branded Side Effects.total_annual_profit",
  "OTC.total_annual_revenue",
  "OTC.total_annual_profit",
  "Brand Subtotal.total_annual_profit",
];

// data: project.keyword_universe
function Branded({ data }) {
  const { theme } = useTheme();
  const [pieData, setPieData] = useState(null);
  const [barData, setBarData] = useState(null);

  useEffect(() => {
    // if the data is not valid, return
    if (!isValidData(data, requiredProperties)) {
      return;
    }

    // rerender the chart when the theme changes
    setPieData(pieOptions(data, "cost-purchase-branded", null, theme));
    setBarData(barOptions(data, "profit-branded", "Revenue and Proift by Branded Category", theme));
  }, [theme]);

  if (!isValidData(data, requiredProperties)) {
    return (
      <div className="forecast-branded h-[550px] grid place-items-center">
        <p>Forecast by Brand data is not available for this project</p>
      </div>
    );
  }

  return (
    <div className="forecast-branded">
      <div className="">
        <h3 className="font-bold">Annual Forecast by Branded Category</h3>
      </div>

      <div className="content">
        <div className="col col-1">
          <div>
            <h4 className="text-center font-bold p-2">
              Branded Profit Estimate
            </h4>
            <p className="text-center text-2xl flex-1 grid place-items-center pt-4">
              { formatCurrency(data['Brand Subtotal'].total_annual_profit, 0) }
            </p>
          </div>

          <div>
            <h4 className="text-center font-bold p-2">
              Profit by Branded Category
            </h4>
            <div className="pie-container">
              <ChartDashboard
                config={pieOptions(data, "cost-purchase-branded", null, theme)}
                id="cost-purchase-branded-container"
              />
            </div>
          </div>
        </div>
        <div className="col col-2">
          <ChartDashboard
            config={barOptions(
              data,
              "profit-branded",
              "Revenue and Profit by Branded Category",
              theme
            )}
            id="profit-branded-container"
          />
        </div>
      </div>
    </div>
  );
}

export default Branded;

const pieOptions = (data, name, title, theme) => {
  const categories = ['Uncategorized - Branded', 'Branded Side Effects', 'OTC'];

  const totalCost = categories.reduce((sum, category) => {
    return sum + (data[category]?.total_annual_profit || 0);
  }, 0);

  const brandedPie = categories.map(category => {
    const cost = data[category]?.total_annual_profit || 0;
    return {
      name: category.split(' - ')[0],
      y: (cost / totalCost) * 100, 
    };
  });

  return {
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: `${name}-keywords-cell`,
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: `${name}-keywords-cell`,
        chartOptions: {
          chart: {
            styledMode: false,
            type: "pie",
            marginBottom: 10,
            height: 322,
          },
          title: {
            text: title,
          },
          tooltip: {
            enabled: true,
            formatter: function () {
              const dollarValue = data[this.point.name]?.total_annual_profit || 0;
              return `<b>${this.point.name}</b>: ${formatCurrency(dollarValue, 0)}`;
            },
            style: {
              color: 'contrast'
            }
          },
          subtitle: {
            text: "",
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: "pointer",
              dataLabels: [
                {
                  enabled: true,
                  format: "<b>{point.name}</b>",
                  style: {
                    color: theme === "dark" ? "white" : "black",
                  },
                },
                {
                  distance: -60,
                  enabled: true,
                  format: "{point.percentage:.1f} %",
                  style: {
                    color: theme === "dark" ? "white" : "black",
                    fontSize: "1.5rem",
                  },
                },
              ],
            },
          },
          series: [
            {
              name: "Percentage",
              type: "pie",
              data: brandedPie,
            },
          ],
        },
      },
    ],
  };
};

const barOptions = (data, name, title, theme) => {
  const barDataRevenue = [
    data["Uncategorized - Branded"].total_annual_revenue,
    data["Branded Side Effects"].total_annual_revenue,
    data["OTC"].total_annual_revenue,
  ];

  const barDataProfit = [
    data["Uncategorized - Branded"].total_annual_profit,
    data["Branded Side Effects"].total_annual_profit,
    data["OTC"].total_annual_profit,
  ];

  return {
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: `${name}-keywords-cell`,
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: `${name}-keywords-cell`,
        chartOptions: {
          chart: {
            type: "bar",
            height: 484,
            marginRight: 70,
          },
          title: {
            text: title,
            align: "left",
          },
          xAxis: {
            categories: ["Uncategorized", "Branded Side Effects", "OTC"],
            title: {
              text: null,
            },
            gridLineWidth: 1,
            lineWidth: 0,
          },
          yAxis: {
            min: 0,
            title: {
              text: null,
            },
            labels: {
              overflow: "justify",
            },
            title: {
              text: "Dollars (millions)",
              align: "high",
            },
            gridLineWidth: 0,
          },
          tooltip: {
            valueSuffix: " millions",
            // formatter 2 decimal places
            formatter: function () {
              return (
                "<b>" +
                this.x +
                "</b><br>" +
                this.series.name +
                ": <b>" + '$' +
                Highcharts.numberFormat(this.y, 0) +
                "</b>"
              );
            },
          },
          plotOptions: {
            bar: {
              borderRadius: "50%",
              dataLabels: {
                enabled: true,
                // format rounded to 0 decimal places
                formatter: function () {
                  return Highcharts.numberFormat(this.y, 0);
                },
              },
              groupPadding: 0.1,
            },
          },
          legend: {
            layout: "vertical",
            align: "right",
            verticalAlign: "top",
            x: -10,
            y: 50,
            floating: true,
          },
          credits: {
            enabled: false,
          },
          series: [
            {
              name: "Revenue",
              data: barDataRevenue,
            },
            {
              name: "Profit",
              data: barDataProfit,
            },
          ],
        },
      },
    ],
  };
};
