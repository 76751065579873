import { Link } from "react-router-dom";

import './Admin.css';

function Admin() {
  return <>
    <div className="admin-page dashboard">
      <div className="page-header">
        <h1>Admin Dashboard</h1>
      </div>

      <section className="admin-links">
        <Link to="/admin/keyword-categories">Keyword Categories</Link>
        <Link to="/admin/library-catalog">Library Catalog</Link>
      </section>

    </div>
  </>
}

export default Admin;