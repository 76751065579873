import { useEffect } from "react";
import Markdown from "react-markdown";

import Highcharts from "highcharts";
import ChartDashboard from "../../../../components/ChartDashboard/ChartDashboard.jsx";

import { useTheme } from "../../../../utils/ThemeManager.jsx"
import { getChartColors } from "../../../../utils/chartUtils.js";

import './DemographicOpportunity.css';

require('highcharts/modules/sunburst.js')(Highcharts);

Highcharts.themeDark = {
  colors: getChartColors('dark'),
};

Highcharts.themeLight = {
  colors: getChartColors('light'),
}

// data: project.condition_analysis
function DemographicOpportunity({ data }) {
  const { theme } = useTheme();

  useEffect(() => {
    theme === 'dark'
      ? Highcharts.setOptions(Highcharts.themeDark)
      : Highcharts.setOptions(Highcharts.themeLight)
  }, [theme]);

  if (!data || Object.keys(data).length === 0 || !data.condition_demographics || data.condition_demographics?.data.length === 0) return <p className="min-h-[550px] px-4 py-8 font-bold grid place-content-center">Demographic Opportunity data is not available.</p>

return <div className="demographic-opportunity">
  <div className="content">
    <div className="col col-1">
      <h4>Summary</h4>
      {
        !data.untreated_vs_treated_chartinfo || data.untreated_vs_treated_chartinfo?.description 
        ? <div className="space-y-4"><Markdown>{ data.untreated_vs_treated_chartinfo.description }</Markdown></div>
        : <p>Summary is not available.</p>
      }
    </div>
    <div className="col col-2">
      {
        !data.condition_demographics || data.condition_demographics?.data.length === 0
          ? <ChartDashboard config={chartOptions(data.condition_demographics)} async={true} id="demographic-opportunity-container" />
          : <p className="min-h-[550px] px-4 py-8 font-bold grid place-content-center">Demographic Opportunity data is not available.</p>
      }
      
    </div>
  </div>
</div>
} 

export default DemographicOpportunity;

const chartOptions = (data) => {
  return {
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: `demographic-opportunity-cell`,
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: `demographic-opportunity-cell`,
        chartOptions: {
          chart: {
            styledMode: false,
            height: 536,
            marginBottom: 40
          },
        
          // Let the center circle be transparent
          colors: ['transparent'].concat(Highcharts.getOptions().colors),
        
          title: {
            text: data.title || 'Demographic Opportunity',
            align: 'left',
            style: {
              color: 'contrast'
            }
          },
        
          subtitle: {
            text: data.subtitle || null,
            align: 'left',
          },
        
          series: [{
            type: 'sunburst',
            data: data.data,
            name: 'Root',
            allowDrillToNode: true,
            borderRadius: 3,
            cursor: 'pointer',
            dataLabels: {
              format: '{point.name}',
              style: {
                textOutline: 'black',
                color: 'white',
              },
              filter: {
                property: 'innerArcLength',
                operator: '>',
                value: 16
              },
            },
            levels: [{
              level: 1,
              levelIsConstant: false,
              dataLabels: {
                filter: {
                  property: 'outerArcLength',
                  operator: '>',
                  value: 64
                }
              }
            }, {
              level: 2,
              colorByPoint: true
            },
            {
              level: 3,
              colorVariation: {
                key: 'brightness',
                to: -0.5
              }
            }, {
              level: 4,
              colorVariation: {
                key: 'brightness',
                to: 0.5
              }
            }]
        
          }],
        
          tooltip: {
            headerFormat: '',
            pointFormat: data.pointFormat,
            style: {
              color: 'white'
            }
          }
        }
      },

    ],
  };
};