import { useState } from "react";
import { Link } from "react-router-dom";

import { $axios } from "../../../services/axiosInstance";

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

// global components
import ToggleSwitch from "../../../components/ToggleSwitch/ToggleSwitch";
import KeywordField from '../../../components/KeywordField/KeywordField'
import Loading from "../../../components/Loading/Loading";

// hook
import useKeywordCategories from "../../../hooks/useKeywordCategories";

import './KeywordCategories.css';

function KeywordCategories() {
  // input fields state
  const [newKeywords, setNewKeywords] = useState({
    branded_side_effects: "",
    otc: "",
    lifestyle: "",
    symptoms: "",
    diagnosis: "",
    treatment: "",
    non_profit: "",
    animals: "",
    explicit: "",
    other_global_condition_negatives: "",
  });

  // loading state for each category
  const [fieldLoading, setFieldLoading] = useState({
    branded_side_effects: false,
    otc: false,
    lifestyle: false,
    symptoms: false,
    diagnosis: false,
    treatment: false,
    non_profit: false,
    animals: false,
    explicit: false,
    other_global_condition_negatives: false,
  });

  // fetch keyword categories data from the hook
  const { keywordsForm, loading, dispatch } = useKeywordCategories();

  const handleUpdateKeywordField = (keyword, value) => {
    setNewKeywords((prevKeywords) => ({
      ...prevKeywords,
      [keyword]: value,
    }));
  };

  const handleToggle = async (e) => {
    // set fielfLoading to true for the field that is being updated
    setFieldLoading((prev) => ({ ...prev, [e.target.name]: true }));

    try {
      const updatedValue = {
        value: e.target.name,
        allowed: e.target.checked,
      };

      const { data } = await $axios.patch(`keywords_list/${keywordsForm[e.target.name].uuid}/`, updatedValue);
      dispatch({ type: 'TOGGLE_ALLOWED', field: e.target.name, value: data.allowed });
  
      // Dispatch the action after the PATCH request is successful
    } catch (error) {
      console.error(error);
      // Consider updating the state to reflect the error, or show an error message to the user
    } finally {
      // set fieldLoading to false after the PATCH request is complete
      setFieldLoading((prev) => ({ ...prev, [e.target.name]: false }));
    }
  };

  const handleAppendItem = async (list, item) => {
    if (!item) return;

    setFieldLoading((prev) => ({ ...prev, [list]: true }));

    const updatedCategoryObj = {
      ...keywordsForm[list],
      keyword_set: [...keywordsForm[list].keyword_set, { value: item }]
    };

    try {
      const { data } = await $axios.patch(`keywords_list/${updatedCategoryObj.uuid}/`, updatedCategoryObj);
      dispatch({ type: 'UPDATE_CATEGORY', field: list, value: data });
    } catch (error) {
      console.error(error);

      // check for 429 status code and display a message to the user
      if (error.response.status === 429) {
        alert('You have reached the maximum number of requests. Please try again later.');
      }
    } finally {
      setFieldLoading((prev) => ({ ...prev, [list]: false }));

      // reset the input field associated with the list
      setNewKeywords((prev) => ({ ...prev, [list]: "" }));
    }
  };

  const handleRemoveItem = async (list, index) => {
    setFieldLoading((prev) => ({ ...prev, [list]: true }));

    // remove the item from the list
    const updatedCategoryObj = {
      ...keywordsForm[list],
      keyword_set: keywordsForm[list].keyword_set.filter((_, i) => i !== index),
    };

    try {
      const { data } = await $axios.patch(`keywords_list/${updatedCategoryObj.uuid}/`, updatedCategoryObj);
      dispatch({ type: 'UPDATE_CATEGORY', field: list, value: data });
    } 
    catch (error) {
      console.error(error);

      // check for 429 status code and display a message to the user
      if (error.response.status === 429) {
        alert('You have reached the maximum number of requests. Please try again later.');
      }
    } finally {
      setFieldLoading((prev) => ({ ...prev, [list]: false }));
    }
  };

  return <>
    { loading && <Loading /> }

    <div className="admin-page keyword-categories-page">
      <nav aria-label="breadcrumbs">
        <ol className="flex gap-2 text-xs md:text-sm items-center">
          <li>
            <Link to="/admin">Admin</Link>
            <FontAwesomeIcon className='ml-2 text-blueberry-500 dark:text-cherry-white' icon={faChevronRight} aria-hidden="true" />
          </li>

          <li className="font-bold text-purple-400 text-lg">
            Keyword Categories
          </li>
        </ol>
      </nav>

      <div className="page-header">
        <h1 className="grad">Keyword Categories</h1>
      </div>

      <section>
        <div className="form-group">
          <div className="form-group-header">
            <h2>Branded (Side Effects)</h2>

            <div className="flex gap-2">
              { fieldLoading.branded_side_effects && <div className="toggle-loader" data-testid="loading-branded_side_effects"></div>} 
              <ToggleSwitch name="branded_side_effects" onChange={handleToggle} checked={keywordsForm.branded_side_effects.allowed} disabled={loading} />
            </div>
          </div>

          <KeywordField
            placeholder={"Add Branded Side Effect Keyword"}
            list={keywordsForm.branded_side_effects.keyword_set}
            type="branded_side_effects"
            value={newKeywords.branded_side_effects}
            onChange={handleUpdateKeywordField}
            onAdd={handleAppendItem}
            onRemove={handleRemoveItem}
            disabled={fieldLoading.branded_side_effects}
          />
        </div>

        <div className="form-group">
          <div className="form-group-header">
            <h2>OTC</h2>
            <div className="flex gap-2">
              { fieldLoading.otc && <div className="toggle-loader" data-testid="loading-otc"></div>} 
              <ToggleSwitch name="otc" onChange={handleToggle} checked={keywordsForm.otc.allowed} disabled={loading} />
            </div>
          </div>

          <KeywordField
            placeholder={"Add OTC Keyword"}
            list={keywordsForm.otc.keyword_set}
            type="otc"
            value={newKeywords.otc}
            onChange={handleUpdateKeywordField}
            onAdd={handleAppendItem}
            onRemove={handleRemoveItem}
            disabled={fieldLoading.otc}
          />
        </div>

        <div className="form-group">
          <div className="form-group-header">
            <h2>Lifestyle</h2>

            <div className="flex gap-2">
              { fieldLoading.lifestyle && <div className="toggle-loader" data-testid="loading-lifestyle"></div>} 
              <ToggleSwitch name="lifestyle" onChange={handleToggle} checked={keywordsForm.lifestyle.allowed} disabled={loading} />
            </div>
          </div>

          <KeywordField
            placeholder={"Add Lifestyle Keyword"}
            list={keywordsForm.lifestyle.keyword_set}
            type="lifestyle"
            value={newKeywords.lifestyle}
            onChange={handleUpdateKeywordField}
            onAdd={handleAppendItem}
            onRemove={handleRemoveItem}
            disabled={fieldLoading.lifestyle}
          />
        </div>

        <div className="form-group">
          <div className="form-group-header">
            <h2>Symptoms</h2>

            <div className="flex gap-2">
              { fieldLoading.symptoms && <div className="toggle-loader" data-testid="loading-symptoms"></div>} 
              <ToggleSwitch name="symptoms" onChange={handleToggle} checked={keywordsForm.symptoms.allowed} disabled={loading} />
            </div>
          </div>

          <KeywordField
            placeholder={"Add Symptoms Keyword"}
            list={keywordsForm.symptoms.keyword_set}
            type="symptoms"
            value={newKeywords.symptoms}
            onChange={handleUpdateKeywordField}
            onAdd={handleAppendItem}
            onRemove={handleRemoveItem}
            disabled={fieldLoading.symptoms}
          />
        </div>

        <div className="form-group">
          <div className="form-group-header">
            <h2>Diagnosis</h2>

            <div className="flex gap-2">
              { fieldLoading.diagnosis && <div className="toggle-loader" data-testid="loading-diagnosis"></div>} 
              <ToggleSwitch name="diagnosis" onChange={handleToggle} checked={keywordsForm.diagnosis.allowed} disabled={loading} />
            </div>
          </div>

          <KeywordField
            placeholder={"Add Diagnosis Keyword"}
            list={keywordsForm.diagnosis.keyword_set}
            type="diagnosis"
            value={newKeywords.diagnosis}
            onChange={handleUpdateKeywordField}
            onAdd={handleAppendItem}
            onRemove={handleRemoveItem}
            disabled={fieldLoading.diagnosis}
          />
        </div>

        <div className="form-group">
          <div className="form-group-header">
            <h2>Treatment</h2>
            <div className="flex gap-2">
              { fieldLoading.treatment && <div className="toggle-loader" data-testid="loading-treatment"></div>}
              <ToggleSwitch name="treatment" onChange={handleToggle} checked={keywordsForm.treatment.allowed} disabled={loading} />
            </div>
          </div>

          <KeywordField
            placeholder={"Add Treatment Keyword"}
            list={keywordsForm.treatment.keyword_set}
            type="treatment"
            value={newKeywords.treatment}
            onChange={handleUpdateKeywordField}
            onAdd={handleAppendItem}
            onRemove={handleRemoveItem}
            disabled={fieldLoading.treatment}
          />
        </div>

        <div className="form-group">
          <div className="form-group-header">
            <h2>Non-Profit</h2>
            <div className="flex gap-2">
              { fieldLoading.non_profit && <div className="toggle-loader" data-testid="loading-non_profit"></div>}
              <ToggleSwitch name="non_profit" onChange={handleToggle} checked={keywordsForm.non_profit.allowed} disabled={loading} />
            </div>
          </div>

          <KeywordField
            placeholder={"Add Non-Profit Keyword"}
            list={keywordsForm.non_profit.keyword_set}
            type="non_profit"
            value={newKeywords.non_profit}
            onChange={handleUpdateKeywordField}
            onAdd={handleAppendItem}
            onRemove={handleRemoveItem}
            disabled={fieldLoading.non_profit}
          />
        </div>

        <div className="form-group">
          <div className="form-group-header">
            <h2>Animals</h2>

            <div className="flex gap-2">
              { fieldLoading.animals && <div className="toggle-loader" data-testid="loading-animals"></div>}
              <ToggleSwitch 
                name="animals" 
                onChange={handleToggle} 
                checked={keywordsForm.animals.allowed} 
                disabled={loading}
              />
              </div>
          </div>

          <KeywordField
            placeholder={"Add Animals Keyword"}
            list={keywordsForm.animals.keyword_set}
            type="animals"
            value={newKeywords.animals}
            onChange={handleUpdateKeywordField}
            onAdd={handleAppendItem}
            onRemove={handleRemoveItem}
            disabled={fieldLoading.animals}
          />
        </div>

        <div className="form-group">
          <div className="form-group-header">
            <h2>Explicit</h2>

            <div className="flex gap-2">
              { fieldLoading.explicit && <div className="toggle-loader" data-testid="loading-explicit"></div>}
              <ToggleSwitch name="explicit" onChange={handleToggle} checked={keywordsForm.explicit.allowed} disabled={loading} />
            </div>
          </div>

          <KeywordField
            placeholder={"Add Explicit Keyword"}
            list={keywordsForm.explicit.keyword_set}
            type="explicit"
            value={newKeywords.explicit}
            onChange={handleUpdateKeywordField}
            onAdd={handleAppendItem}
            onRemove={handleRemoveItem}
            disabled={fieldLoading.explicit}
          />
        </div>

        <div className="form-group">
          <div className="form-group-header">
            <h2>Other Global Condition Negatives</h2>
            <div className="flex gap-2">
              { fieldLoading.other_global_condition_negatives && <div className="toggle-loader" data-testid="loading-branded"></div>}
              <ToggleSwitch name="other_global_condition_negatives" onChange={handleToggle} checked={keywordsForm.other_global_condition_negatives.allowed} disabled={loading} />
            </div>
          </div>

          <KeywordField
            placeholder={"Add Other Global Condition Negatives Keyword"}
            list={keywordsForm.other_global_condition_negatives.keyword_set}
            type="other_global_condition_negatives"
            value={newKeywords.other_global_condition_negatives}
            onChange={handleUpdateKeywordField}
            onAdd={handleAppendItem}
            onRemove={handleRemoveItem}
            disabled={fieldLoading.other_global_condition_negatives}
          />
        </div>
      </section>
    </div>
  </>
}

export default KeywordCategories;
