import { useEffect } from "react";

import Highcharts from "highcharts";
import ChartDashboard from "../../../../components/ChartDashboard/ChartDashboard.jsx";

import { useTheme } from "../../../../utils/ThemeManager.jsx"
import { getChartColors } from "../../../../utils/chartUtils.js";

import './ConversionFunnel.css';

require('highcharts/modules/sunburst.js')(Highcharts);

Highcharts.themeDark = {
  colors: getChartColors('dark'),
};

Highcharts.themeLight = {
  colors: getChartColors('light'),
}

// data: project.keyword_universe
function ConversionFunnel({ data }) {
  const { theme } = useTheme();

  useEffect(() => {
    theme === 'dark'
      ? Highcharts.setOptions(Highcharts.themeDark)
      : Highcharts.setOptions(Highcharts.themeLight)
  }, [theme]);

  if (!data || !Object.keys(data).length) {
    return <p className="min-h-[550px] px-4 py-8 font-bold grid place-content-center">Conversion funnel data is not available.</p>
  };

return <div className="conversion-funnel">
  <div className="content">
    <div className="col col-1">
      <ChartDashboard config={chartOptions(data)} async={true} id="conversion-funnel-container" />
    </div>
  </div>
</div>
} 

export default ConversionFunnel;

const chartOptions = (data) => {


  return {
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: `conversion-funnel-cell`,
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: `conversion-funnel-cell`,
        chartOptions: {
          chart: {
            type: 'bar',
            height: 536,
            events: {
              load: function() {
                // come back to this later
                // const chart = this;
                // const colors = Highcharts.getOptions().colors;

                // chart.renderer.rect(1, 398, 300, 50, 5)
                // .attr({
                //   'stroke-width': 2,
                //   stroke: colors[3],
                //   fill: 'none',
                //   zIndex: 3,
                //   'pointer-events': 'none',
                // })
                // .add();
              }
            },
          },
          title: {
            text: 'ACNU Conversion Funnel',
            align: 'left',
          },
          subtitle: {
            text: `There were ${ data['Grand Total'].search_volume_avg_annual_total.toLocaleString() } searches.`,
            align: 'left',
          },
          xAxis: {
            categories: ['Site Visits', 'Complete "Take Quiz"', 'Complete "Get Result"', 'Complete "Ok to Use"', 'Set Up Account & Purchase'],
            title: {
              text: null
            }
          },
          yAxis: {
            min: 0,
            title: {
              text: null
            },
          },
          tooltip: {
            valueSuffix: '',
            formatter: function() {
              // precision to 2 decimal places
              const colors = Highcharts.getOptions().colors;
              const colorIndex = this.point.colorIndex || 0;
              const color = colors[colorIndex];

              const value = this.y.toLocaleString(undefined, { maximumFractionDigits: 2 });
              return `<span style="color:${color}">●</span> <b>${this.series.name}</b><br>${this.x}: ${value}`;
            }
          },
          plotOptions: {
            bar: {
              stacking: 'normal',
              dataLabels: {
                enabled: false,
              }
            }
          },
          series: [
            {
              name: 'Branded',
              data: [
                data['Uncategorized - Branded']?.estimated_visits || data['Branded']?.estimated_visits || 0,
                data['Uncategorized - Branded']?.estimated_tass_starts || data['Branded']?.estimated_tass_starts || 0,
                data['Uncategorized - Branded']?.estimated_tass_completes || data['Branded']?.estimated_tass_completes || 0,
                data['Uncategorized - Branded']?.estimated_tass_ok || data['Branded']?.estimated_tass_ok || 0,
                data['Uncategorized - Branded']?.estimated_purchases || data['Branded']?.estimated_purchases || 0,
              ]
            },
            {
              name: 'OTC',
              data: [
                data['OTC'].estimated_visits || 0,
                data['OTC'].estimated_tass_starts || 0,
                data['OTC'].estimated_tass_completes || 0,
                data['OTC'].estimated_tass_ok || 0,
                data['OTC'].estimated_purchases || 0,
              ]
            },
            {
              name: 'Treatment',
              data: [
                data['Treatment'].estimated_visits || 0,
                data['Treatment'].estimated_tass_starts || 0,
                data['Treatment'].estimated_tass_completes || 0,
                data['Treatment'].estimated_tass_ok || 0,
                data['Treatment'].estimated_purchases || 0,
              ]
            },
            {
              name: 'Condition',
              data: [
                data['Uncategorized - Condition']?.estimated_visits         || data['Condition']?.estimated_visits          || 0,
                data['Uncategorized - Condition']?.estimated_tass_starts    || data['Condition']?.estimated_tass_starts     || 0,
                data['Uncategorized - Condition']?.estimated_tass_completes || data['Condition']?.estimated_tass_completes  || 0,
                data['Uncategorized - Condition']?.estimated_tass_ok        || data['Condition']?.estimated_tass_ok         || 0, 
                data['Uncategorized - Condition']?.estimated_purchases      || data['Condition']?.estimated_purchases       || 0,
              ]
            },
            {
              name: 'Lifestyle',
              data: [
                data['Lifestyle'].estimated_visits || 0,
                data['Lifestyle'].estimated_tass_starts || 0,
                data['Lifestyle'].estimated_tass_completes || 0, 
                data['Lifestyle'].estimated_tass_ok || 0, 
                data['Lifestyle'].estimated_purchases || 0,
              ]
            },
          ],
        }
      },
    ],
  };
};