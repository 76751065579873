import { useEffect, useState } from 'react';
import { Fragment } from 'react';

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import Highcharts from 'highcharts/highstock';

// styles
import "./ForecastTable.css";

// theme
import { useTheme } from '../../../../utils/ThemeManager.jsx'
import { getChartColors } from '../../../../utils/chartUtils.js';

// table utils
import { roundFloat, formatCurrency } from '../../../../utils/tableUtils.js';

// HeaderRow
// is the header row of the table
export const HeaderRow = () => {
  return <>
    <div className="grid-item header">Category</div>
    <div className="grid-item header center">Count</div>
    <div className="grid-item header center">Annual Volume</div>
    <div className="grid-item header">TASS Starts</div>
    <div className="grid-item header center">TASS Completes</div>
    <div className="grid-item header center">TASS OK to Use</div>
    <div className="grid-item header center">Purchases</div>
    <div className="grid-item header center">Cost Per<br />Acquisition</div>
    {/* <div className="grid-item header center">Total Monthly Revenue</div> */}
    <div className="grid-item header center">Annual Revenue</div>
    {/* <div className="grid-item header center">Total Cost Per Month</div> */}
    {/* <div className="grid-item header center">Total Monthly Profit</div> */}
    <div className="grid-item header center">Annual Profit</div>
  </>
}

// TableRow 
// is for each keyword category
export const TableRow = ({row, value, color, showBranded, showCondition}) => {
  // remove everything after the Uncategorized keyword
  const formattedRow = row.split(' - ')[0];

  const categoryMap = {
    "Uncategorized - Branded": 'Brand',
    OTC: 'Brand',
    "Branded Side Effects": 'Brand',
    "Uncategorized - Condition": 'Condition',
    Lifestyle: 'Condition',
    Symptoms: 'Condition',
    Diagnosis: 'Condition',
    Treatment: 'Condition',
  }

  if(categoryMap[row] === 'Brand' && !showBranded) {
    return null;
  }

  if(categoryMap[row] === 'Condition' && !showCondition) {
    return null;
  }

  return <Fragment key={`keyword-forecast-${row}`}>
  <div className={`category grid-item data whitespace-nowrap space-x-2 gap-2`}>
    <svg width="12" height="12" fill={color}>
      <rect width="12" height="12" rx="6" ry="6" />
    </svg>
    {formattedRow}
  </div>

  <div className={`count grid-item data right`}>
    { roundFloat(value.keyword_count) }
  </div>

  <div className={`annual-volume grid-item data right`}>
    { roundFloat(value.search_volume_avg_annual_total) }
  </div>

  <div className={`tass-starts grid-item data right`}>
    { roundFloat(value.estimated_tass_starts) }
  </div>

  <div className={`tass-completes grid-item data right`}>
    { roundFloat(value.estimated_tass_completes) }
  </div>

  <div className={`tass-ok grid-item data right`}>
    { roundFloat(value.estimated_tass_ok) }
  </div>

  <div className={`estimated-purchase grid-item data right`}>
    { roundFloat(value.estimated_purchases) }
  </div>

  <div className={`acquisition-cost grid-item data right`}>
    { formatCurrency(value.cost_per_acquisition) }  
  </div>

  {/* <div className={`total-monthly-revenue grid-item data right`}>
    { formatCurrency(value.total_monthly_revenue) }
  </div> */}

  <div className={`total-annual-revenue grid-item data right`}>
    { formatCurrency(value.total_annual_revenue, 0) }
  </div>

  {/* <div className="total-monthly-cost grid-item data right">
    { formatCurrency(value.total_monthly_cost) }  
  </div> */}

  {/* <div className="total-monthly-profit grid-item data right">
    { formatCurrency(value.total_monthly_profit) }  
  </div> */}

  <div className="estimated-cost-monthly grid-item data right">
    { formatCurrency(value.total_annual_profit, 0) }  
  </div>
</Fragment>
}

// Subtotal Row 
// is especially for the "Branded Subtotal" and "Condition Subtotal" rows
const TotalRow = ({ row, value }) => {
  return <Fragment key={`keyword-category-${row}`}>
    <div className={`category-grand-total grid-item data total whitespace-nowrap`}>
      {row}
    </div>

    <div className="category-grand-total count grid-item data total right">
      { roundFloat(value.total_keyword_count) }
    </div>

    <div className="category-grand-total annual-volume grid-item data total right">
      { roundFloat(value.search_volume_avg_annual_total) }
    </div>

    <div className={`category-grand-total tass-starts grid-item data total right`}>
      { roundFloat(value.total_tass_starts) }
    </div>

    <div className={`category-grand-total tass-completes grid-item data total right`}>
      { roundFloat(value.total_tass_completes) }
    </div>

    <div className={`category-grand-total tass-ok grid-item data total right`}>
      { roundFloat(value.total_tass_oktouse) }
    </div>

    <div className={`category-grand-total estimated-purchase grid-item data total right`}>
      { roundFloat(value.total_purchases) }
    </div>

    <div className={`category-grand-total acquisition-cost grid-item data total right`}>
      { formatCurrency(value.avg_cost_per_acquisition) }  
    </div>

    {/* <div className={`category-grand-total total-monthly-revenue grid-item data total right`}>
      { formatCurrency(value.total_monthly_revenue) }
    </div> */}

    <div className={`category-grand-total total-annual-revenue grid-item data total right`}>
      { formatCurrency(value.total_annual_revenue, 0) }
    </div>

    {/* <div className="category-grand-total total-monthly-cost grid-item data total right">
      { formatCurrency(value.total_monthly_cost) }  
    </div> */}

    {/* <div className="category-grand-total total-monthly-profit grid-item data total right">
      { formatCurrency(value.total_monthly_profit) }  
    </div> */}

    <div className="category-grand-total estimated-cost-monthly grid-item data total right">
      { formatCurrency(value.total_annual_profit, 0) }  
    </div>
  </Fragment>
}

// Subtotal Row 
// is especially for the "Branded Subtotal" and "Condition Subtotal" rows
const SubtotalRow = ({ row, value, setBranded, showBranded, setCondition, showCondition }) => {

  const toggleSection = (e) => {
    const section = e.currentTarget.value;

    if(section === 'Brand') {
      setBranded(!showBranded);
    } else if(section === 'Condition') {
      setCondition(!showCondition);
    }
  }

  return <Fragment key={`keyword-category-${row}`}>
    <div className={`category-${row.replace(' ', '-')} grid-item data subtotal whitespace-nowrap gap-2`}>
      <button className="flex items-center gap-2" onClick={toggleSection} value={row.split(' ')[0]}>
        <FontAwesomeIcon icon={faChevronRight} size="xs" className={`transform ${row.split(' ')[0] === 'Brand' ? showBranded ? 'rotate-90' : '' : showCondition ? 'rotate-90' : ''}`} />
        {row}
      </button>
    </div>

    <div className="count grid-item data subtotal right">
      { roundFloat(value.total_keyword_count) }
    </div>

    <div className="annual-volume grid-item data subtotal right">
      { roundFloat(value.search_volume_avg_annual_total) }
    </div>

    <div className={`tass-starts grid-item data subtotal right`}>
      { roundFloat(value.total_tass_starts) }
    </div>

    <div className={`tass-completes grid-item data subtotal right`}>
      { roundFloat(value.total_tass_completes) }
    </div>

    <div className={`tass-ok grid-item data subtotal right`}>
      { roundFloat(value.total_tass_oktouse) }
    </div>

    <div className={`estimated-purchase grid-item data subtotal right`}>
      { roundFloat(value.total_purchases) }
    </div>

    <div className={`acquisition-cost grid-item data subtotal right`}>
      { formatCurrency(value.avg_cost_per_acquisition) }  
    </div>

    {/* <div className={`total-monthly-revenue grid-item data subtotal right`}>
      { formatCurrency(value.total_monthly_revenue) }
    </div> */}

    <div className={`total-annual-revenue grid-item data subtotal right`}>
      { formatCurrency(value.total_annual_revenue, 0) }
    </div>

    {/* <div className="total-monthly-cost grid-item data subtotal right">
      { formatCurrency(value.total_monthly_cost) }  
    </div> */}

    {/* <div className="total-monthly-profit grid-item data subtotal right">
      { formatCurrency(value.total_monthly_profit) }  
    </div> */}

    <div className="estimated-cost-monthly grid-item data subtotal right">
      { formatCurrency(value.total_annual_profit, 0) }  
    </div>
  </Fragment>
}

// TableRows 
// the main component that maps over the data object and returns a TableRow or SubtotalRow component
// data: project.keyword_universe
const TableRows = ({ data }) => {
  const { theme } = useTheme();
  const [showBranded, setShowBranded] = useState(false);
  const [showCondition, setShowCondition] = useState(false);
  const keys = Object.keys(data);

  // maintain this key order
  const keyOrder = {
    "Condition Subtotal": 0,
    "Uncategorized - Condition": 1,
    Lifestyle: 2,
    Symptoms: 3,
    Diagnosis: 4,
    Treatment: 5,
    "Brand Subtotal": 6,
    "Uncategorized - Branded": 7,
    "Branded Side Effects": 8,
    OTC: 9,
    "Grand Total": 10,
  };
  const filteredKeys = keys.filter(key => keyOrder.hasOwnProperty(key));
  const sortedKeys = filteredKeys.sort((a, b) => keyOrder[a] - keyOrder[b]);

  const colors = getChartColors(theme);
  const colorMap = {
    "Brand Subtotal": theme === 'dark' ? '#ffffff' : '#555555',
    "Uncategorized - Branded": colors[5],
    OTC: colors[7],
    "Branded Side Effects": colors[6],
    "Uncategorized - Condition": colors[0],
    "Condition Subtotal": theme === 'dark' ? '#ffffff' : '#555555',
    Lifestyle: colors[1],
    Symptoms: colors[2],
    Diagnosis: colors[3],
    Treatment: colors[4],
    "Grand Total": theme === 'dark' ? '#ffffff' : '#555555',
  }

  useEffect(() => {
    theme === 'dark'
      ? Highcharts.setOptions(Highcharts.themeDark)
      : Highcharts.setOptions(Highcharts.themeLight)
  }, [theme]);

  // map over the keys and return a table row for each
  return sortedKeys.map((key, index) => {
    // if the key is a subtotal row, render the SubtotalRow component
    if(key.includes('Subtotal')) {
      return <SubtotalRow
        row={key}
        value={data[key]}
        index={index}
        key={key}
        setBranded={setShowBranded}
        setCondition={setShowCondition}
        showBranded={showBranded}
        showCondition={showCondition}
      />
    }

    if(key === "Grand Total") {
      return <TotalRow
        row={key}
        value={data[key]}
        index={index}
        key={key}
      />
    }

    return <TableRow
      row={key}
      value={data[key]}
      key={key}
      color={colorMap[key]}
      showBranded={showBranded}
      showCondition={showCondition}
    />
  })
}

// data: project.keyword_universe
const ForecastTable = ({ data }) => {
  return (
    <div className="grid-container forecast-category" role="table">
      <HeaderRow />
      <TableRows data={data} />
    </div>
  )
}

export default ForecastTable;