import ChartDashboard from "../../components/ChartDashboard/ChartDashboard.jsx";

import './DirectCompetitorChart.css'

// formatting the large dollar amounts returned in this chart data
export const largeNumberFormatter = (num) => {
  if (num > 999999999) {
    return `${(num / 1000000000).toFixed(1)}B`;
  } else if (num > 999999) {
    return `${(num / 1000000).toFixed(1)}M`;
  } else if (num > 999) {
    return `${(num / 1000).toFixed(1)}K`;
  } else {
    return num;
  }
};

const DirectCompetitorChart = ({ data, id }) => {
  const chartOptions = {
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: `cell-0-${id}`,
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: `cell-0-${id}`,
        chartOptions: {
          chart: {
            zoomType: "xy",
            reflow: true,
          },
          legend: {
            enabled: false,
          },
          title: {
            text: null,
          },
          subtitle: {
            text: null,
          },
          accessibility: {
            point: {
              valueDescriptionFormat:
                "{index}. {point.brand_name}, launch year: {point.x}, since-launch revenue: ${point.y}",
            },
          },
          xAxis: {
            title: {
              text: "Launch Year",
            },
            labels: {
              format: "{value}",
            },
            plotLines: [
              {
                dashStyle: "dot",
                width: 1,
                value: 0,
                label: {
                  rotation: 0,
                  y: 15,
                  style: {
                    fontStyle: "italic",
                  },
                  text: "",
                },
                zIndex: 3,
              },
            ],
            accessibility: {
              rangeDescription: "Range: 1990 to 2006.",
            },
          },

          yAxis: {
            startOnTick: false,
            endOnTick: false,
            title: {
              text: "Since-launch Revenue (USD)",
            },
            labels: {
              formatter: function () {
                return largeNumberFormatter(+this.value);
              },
            },
            maxPadding: 0.2,
            plotLines: [
              {
                dashStyle: "dot",
                width: 1,
                value: 0,
                label: {
                  align: "right",
                  style: {
                    fontStyle: "italic",
                  },
                  text: "",
                  x: -10,
                },
                zIndex: 3,
              },
            ],
            accessibility: {
              rangeDescription: "Range: $1 Billion USD to $150 Billion USD",
            },
          },

          tooltip: {
            formatter: function () {
              return `<h3 style="font-weight: bold; font-size: 1rem">${
                this.point.brand_name
              }</h3>
              <br />
              <p>
                <strong>Launch Year:</strong> ${this.point.x}
              </p>
              <br />
              <p>
                <strong>Since-Launch Revenue:</strong> $${largeNumberFormatter(
                  this.point.y
                )}
              </p>
              <br />
              <p>
                <strong>Manufacturer:</strong> ${this.point.manufacturer}
              </p>
              <br />
              <p>
                <strong>Generic Name:</strong> ${this.point.z}
              </p>`;
            },
          },

          plotOptions: {
            series: {
              dataLabels: {
                enabled: true,
                format: "{point.brand_name}",
              },
            },
          },

          series: [
            {
              type: "bubble",
              data: data,
              colorByPoint: true,
            },
          ],
        },
      },
    ],
  };

  return <div className="direct-comp-chart"><ChartDashboard config={chartOptions} id={id} /></div>;
};

export default DirectCompetitorChart;
