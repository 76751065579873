import { useEffect, useState } from "react";

import Highcharts from "highcharts";
import ChartDashboard from "../../../components/ChartDashboard/ChartDashboard.jsx";

import { useTheme } from "../../../utils/ThemeManager.jsx";
import { getChartColors } from "../../../utils/chartUtils.js";

import "./PrescriptionTrend.css";

Highcharts.themeDark = {
  colors: getChartColors('dark'),
};

Highcharts.themeLight = {
  colors: getChartColors('light'),
}

Highcharts.setOptions({
	plotOptions: {
		series: {
			animation: false
		}
	}
});

// data = project.condition_analysis.population_chart_input
const PrescriptionTrend = ({ data }) => {
  const { theme } = useTheme();

  useEffect(() => {
    theme === 'dark'
      ? Highcharts.setOptions(Highcharts.themeDark)
      : Highcharts.setOptions(Highcharts.themeLight)
  }, [theme]);

  if (!data || Object.keys(data).length === 0) {
    return <p className="px-4 py-8 font-bold text-center">Prescription trend data is not available.</p>
  }

  return <div className="">
    <ChartDashboard config={lineChartOptions(data)} id="population-container" />
  </div>;
};

export default PrescriptionTrend;

// chart options
const lineChartOptions = (data) => {
  const series = [];

  // Check if the first series exists and is valid
  if (data.labels[0] && data.data1 && data.data1.length > 0) {
    series.push({
      name: data.labels[0],
      data: data.data1,
    });
  }
  
  // Check if the second series exists and is valid
  if (data.labels[1] && data.data2 && data.data2.length > 0) {
    series.push({
      name: data.labels[1],
      data: data.data2,
    });
  }

  return {  
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: "population-cell-0",
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: "population-cell-0",
        chartOptions: {
          title: {
            text: data.title || null,
            align: "left",
          },
          subtitle: {
            text: data.subtitle || null,
            align: "left",
          },
          yAxis: {
            title: {
              text: data.yAxisText || null,
            },
          },
          xAxis: {
            categories: data.year || [],
          },
          legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "bottom",
          },
          plotOptions: {
            series: {
              label: {
                connectorAllowed: false,
              },
            },
          },
          responsive: {
            rules: [
              {
                condition: {
                  maxWidth: 768,
                },
                chartOptions: {
                  legend: {
                    layout: "horizontal",
                    align: "center",
                    verticalAlign: "bottom",
                    itemStyle: {
                      whiteSpace: "nowrap",
                      color: 'red'
                    }
                  },
                },
              },
            ],
          },
          series: series
        },
      },
    ],}
};