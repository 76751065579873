// initial form state
const initialFormState = {
  branded_side_effects: {
    uuid: null,
    name: "Branded Side Effects",
    allowed: false,
    keyword_set: [],
  },
  otc: {
    uuid: null,
    name: "OTC",
    allowed: false,
    keyword_set: []
  },
  lifestyle: {
    uuid: null,
    name: "Lifestyle",
    allowed: false,
    keyword_set: [],
  },
  symptoms: {
    uuid: null,
    name: "Symptoms",
    allowed: false,
    keyword_set: [],
  },
  diagnosis: {
    uuid: null,
    name: "Diagnosis",
    allowed: false,
    keyword_set: [],
  },
  treatment: {
    uuid: null,
    name: "Treatment",
    allowed: false,
    keyword_set: [],
  },
  non_profit: {
    uuid: null,
    name: "Non Profit",
    allowed: false,
    keyword_set: [],
  },
  animals: {
    uuid: null,
    name: "Animals",
    allowed: false,
    keyword_set: [],
  },
  explicit: {
    uuid: null,
    name: "Explicit",
    allowed: false,
    keyword_set: [],
  },
  other_global_condition_negatives: {
    uuid: null,
    name: "Explicit",
    allowed: false,
    keyword_set: [],
  },
}

const formReducer = (state, action) => {
  switch (action.type) {
    case 'SET_PROJECT_DATA':
      const newState = { ...state };

      action.projectData.forEach((item) => {
        newState[item.snake_case_name] = { ...item };
      });

      return {
        ...state,
        ...newState,
      };
    case 'UPDATE_CATEGORY':
      return { ...state, [action.field]: action.value };
    case 'TOGGLE_ALLOWED':
      if (state.hasOwnProperty(action.field)) {
        const updatedState = {
          ...state,
          [action.field]: {
            ...state[action.field],
            allowed: action.value,
          },
        };
        return updatedState;
      }
    default:
      return state;
  }
};

export { initialFormState, formReducer };