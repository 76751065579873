import { useEffect } from "react";

import Highcharts from "highcharts";
import ChartDashboard from "../../../../components/ChartDashboard/ChartDashboard.jsx";

import { useTheme } from "../../../../utils/ThemeManager.jsx";
import { getChartColors } from "../../../../utils/chartUtils.js";
import { formatCurrency } from "../../../../utils/tableUtils.js";

import { isValidData } from "../../../../utils/chartUtils.js";

import "./Type.css";

require("highcharts/modules/sunburst.js")(Highcharts);

Highcharts.themeDark = {
  colors: getChartColors("dark"),
};

Highcharts.themeLight = {
  colors: getChartColors("light"),
};

const requiredProperties = [
  "Brand Subtotal.estimated_monthly_cost_trend",
  "Condition Subtotal.estimated_monthly_cost_trend",
  "Grand Total.search_volume_months",
];

// data: project.keyword_universe
function Type({ data }) {
  const { theme } = useTheme();

  useEffect(() => {
    theme === "dark"
      ? Highcharts.setOptions(Highcharts.themeDark)
      : Highcharts.setOptions(Highcharts.themeLight);
  }, [theme]);

  if (!isValidData(data, requiredProperties)) {
    return <p className="min-h-[550px] px-4 py-8 font-bold grid place-content-center">Estimated spend by type data is not available.</p>
  };

  return (
    <div className="estimated-spend-type">
      <div className="content">
        <div className="col col-1">
          <ChartDashboard
            config={chartOptions(data, theme)}
            async={true}
            id="estimated-spend-type-container"
          />
        </div>
      </div>
    </div>
  );
}

export default Type;

const chartOptions = (data, theme) => {
  return {
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: `estimated-spend-type-cell`,
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: `estimated-spend-type-cell`,
        chartOptions: {
          chart: {
            type: "column",
            styledMode: false,
            height: 550,
          },
          title: {
            text: "Estimated Monthly Spend by Type",
            align: "left",
            style: {
              color: 'contrast'
            }
          },
          xAxis: {
            categories: data['Grand Total'].search_volume_months,
            labels: {
              style: {
                color: 'contrast',
                fontSize: "14px", 
              }
            }
          },
          yAxis: {
            min: 0,
            title: {
              text: "Spend in Millions",
              style: {
                color: 'contrast'
              }
            },
            labels: {
              style: {
                color: 'contrast'
              }
            },
            stackLabels: {
              enabled: true,
              formatter: function () {
                // dollar sign and 2 decimal places
                return formatCurrency(this.total);
              },
              style: {
                fontSize: "14px", 
                fontWeight: "normal",
                color: 'contrast'
              }
            },
          },
          legend: {
            align: "left",
            x: 60,
            verticalAlign: "top",
            y: 50,
            floating: true,
            borderWidth: 1,
            shadow: false,
            itemStyle: {
              color: theme === "dark" ? "#fff" : "#000",
            },
            itemHoverStyle: {
              color: '#ccc'
            }
          },
          tooltip: {
            headerFormat: "<b>{point.x}</b><br/>",
            formatter: function () {
              const colorDot = `<span style="color:${this.point.color};">●</span>`;
              return `<b>${this.x}</b><br/>${colorDot} ${this.series.name}: ${formatCurrency(this.y)}`;
            },
            style: {
              color: 'contrast'
            },
          },
          plotOptions: {
            column: {
              stacking: "normal",
            },
          },
          series: [
            {
              name: "Branded",
              data: data["Brand Subtotal"].estimated_monthly_cost_trend
            },
            {
              name: "Condition",
              data: data["Condition Subtotal"].estimated_monthly_cost_trend
            },
          ],
        },
      },
    ],
  };
};
