import { useEffect } from "react";

import Highcharts from "highcharts";
import ChartDashboard from "../../../components/ChartDashboard/ChartDashboard.jsx";

import { useTheme } from "../../../utils/ThemeManager.jsx";
import { getChartColors } from "../../../utils/chartUtils.js";

import "./SearchVolMonthType.css";

Highcharts.themeDark = {
  colors: getChartColors('dark'),
};

Highcharts.themeLight = {
  colors: getChartColors('light'),
}

Highcharts.setOptions({
	plotOptions: {
		series: {
			animation: false
		}
	}
});

// data: project.keyword_universe
// Search Volume by Month by Type
const SearchVolMonthType = ({ data }) => {

  const { theme } = useTheme() || 'dark';

  useEffect(() => {
    theme === 'dark'
      ? Highcharts.setOptions(Highcharts.themeDark)
      : Highcharts.setOptions(Highcharts.themeLight)
  }, [theme]);

  if (!data || Object.keys(data).length === 0) {
    return <p className="px-4 py-8 font-bold text-center">Consumer Journey data is not available.</p>
  }

  return <ChartDashboard config={chartOptions(data)} async={true} id="consumer-journey" />;
};

export default SearchVolMonthType;

const getDataValue = (data, key, subKey, defaultValue = 0) => {
  return data?.[key]?.[subKey] ?? defaultValue;
};

const chartOptions = (data) => {

  return {
      gui: {
        layouts: [
          {
            rows: [
              {
                cells: [
                  {
                    id: "consumer-journey-cell",
                  },
                ],
              },
            ],
          },
        ],
      },
      components: [
        {
          type: "Highcharts",
          cell: "consumer-journey-cell",
          chartOptions: {
            chart: {
              type: 'area',
              spacingBottom: 10,
              spacingTop: 10,
              spacingLeft: 10,
              spacingRight: 10,
              height: 500,
            },
            accessibility: {
              description: 'Image description:'
            },
            title: {
              text: 'Search Volume by Month by Type'
            },
            subtitle: {
              text: 'Source: <a href="https://semrush.com" target="_blank">SEMRush</a>'
            },
            xAxis: {
              type: 'datetime',
              accessibility: {
                rangeDescription: 'Range: 12 months.'
              },
              dateTimeLabelFormats: {
                month: '%b' // Display abbreviated month name
              },
              gridLineColor: 'rgb(229, 228, 226, 1)',
              gridLineWidth: 1
            },
            yAxis: {
              title: {
                text: 'Total Search Volume',
                style: {
                  fontSize: '18px'
                },
                margin: 16
              },
              gridLineColor: 'rgb(229, 228, 226, 1)',
              gridLineWidth: 1
            },
            tooltip: { 
              useHTML: true,
              shadow: false,
              borderWidth: 2,
              headerFormat: '<table>',
              pointFormat: '<tr><th>{point.x:%b}</th></tr>' +
              '<tr><th>Monthly Volume:</th><td>{point.y}</td></tr>',
              footerFormat: '</table>'
            },
            plotOptions: {
              area: {
                marker: {
                  enabled: false,
                  symbol: 'circle',
                  radius: 8,
                  states: {
                    hover: {
                      enabled: true
                    }
                  }
                }
              }
            },
            legend: {
              align: 'center',
              verticalAlign: 'top',
              layout: 'horizontal',
              backgroundColor: 'rgb(248,248,248)',
              borderWidth: '1',
              borderColor: 'rgb(230,230,230)'
            },
            series: [{
              name: 'Condition Search Volume',
              data: data['Condition Subtotal']?.search_volume_monthly_totals || [],
              pointStart: Date.UTC(2024, 0, 1), // Start at January 2024
              pointInterval: 30.44 * 24 * 3600 * 1000, // Interval of one month
              color: {
                  linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1
                  },
                  stops: [
                    [0, 'rgb(34, 0, 112)'],
                    [.7, 'rgb(34, 0, 112, 1)']
        
                  ]
                }
            },
            {
              name: 'Branded Search Volume',
              data: data['Brand Subtotal']?.search_volume_monthly_totals || [],
              pointStart: Date.UTC(2024, 0, 1), // Start at January 2024
              pointInterval: 30.44 * 24 * 3600 * 1000, // Interval of one month
              color: {
                  linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1
                  },
                  stops: [
                    [0, 'rgb(255, 20, 145, 1)'],
                    [.7, 'rgb(255, 20, 145, 1)']          ]
                }
            }]
        }
        },
      ],
  }
}