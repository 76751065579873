import { Link } from "react-router-dom";

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import '../Admin.css';

function KeywordProfile() {
  return <>
    <div className="admin-page keyword-profile">
    <nav aria-label="breadcrumbs">
        <ol className="flex gap-2 text-xs md:text-sm items-center">
          <li>
            <Link to="/admin">Admin</Link>
            <FontAwesomeIcon className='ml-2 text-black dark:text-white' icon={faChevronRight} aria-hidden="true" />
          </li>

          <li>
          <Link to="/admin/library-catalog">Library Catalog</Link>
            <FontAwesomeIcon className='ml-2 text-black dark:text-white' icon={faChevronRight} aria-hidden="true" />
          </li>

          <li>
          <Link to="/admin/keyword-universe">Keyword Universe</Link>
            <FontAwesomeIcon className='ml-2 text-black dark:text-white' icon={faChevronRight} aria-hidden="true" />
          </li>

          <li className="font-bold text-purple-400 text-lg">
            Keyword Profile
          </li>
        </ol>
      </nav>
      <div className="page-header">
        <h1 className="grad">Keyword Profile</h1>
      </div>

      <section>
        keyword profile content
      </section>
    </div>
  </>
}

export default KeywordProfile;