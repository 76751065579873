import './ToggleSwitch.css'; // Make sure to create this CSS file

const ToggleSwitch = ({ name, onChange, checked, disabled }) => {
  if (!name) {
    console.warn("ToggleSwitch component: name prop is not provided");
  }

  if (!onChange) {
    console.warn("ToggleSwitch component: onChange prop is not provided");
  }

  return (
    <div className="toggle-wrapper">
      <input
        id={`toggle-switch-checkbox-${name}`}
        name={ name }
        className="toggle-switch-checkbox"
        type="checkbox"
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
      <label className="toggle" htmlFor={`toggle-switch-checkbox-${name}`}>
        <span className="toggle-switch"></span>
        <span className="sr-only">Toggle {name}</span>
      </label>
    </div>
  );
};

export default ToggleSwitch;
