// react
import { useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Markdown from 'react-markdown'

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faChartPie,
  faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";

// hooks
import useProject from "../../hooks/useProject";
import useBrandAnalysis from "../../hooks/useBrandAnalysis";

// global components
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Loading from "../../components/Loading/Loading";
import RefreshButton from "../../components/RefreshButton/RefreshButton";
import ACNUInfo from "../Project/ACNUInfo/ACNUInfo";

// local components
import ChartSettings from "./Charts/ChartSettings/ChartSettings";
import ForecastTable from "./Charts/ForecastTable/ForecastTable";
import ForecastCharts from "./Charts/Forecast/ForecastCharts";
import ConditionOpportunity from "./Charts/ConditionOpportunity/ConditionOpportunity";
import DemographicOpportunity from "./Charts/DemographicOpportunity/DemographicOpportunity";
import SearchOpportunity from "./Charts/SearchOpportunity/SearchOpportunity";
import SearchSpendRec from "./Charts/SearchSpendRec/SearchSpendRec";
import ConversionFunnel from "./Charts/ConversionFunnel/ConversionFunnel";
import AnnualForecast from "../Project/AnnualForecast/AnnualForecast";
import Branded from "./Charts/Forecast/Branded/Branded";
import Condition from "./Charts/Forecast/Condition/Condition";

import TolerabilityChart from "./Charts/SafetyTolerability/TolerabilityChart";
import Warnings from "./Charts/SafetyTolerability/Warnings";
import StopUsageChart from "./Charts/StopUsageChart/StopUsageChart";

import "./ACNUFeasibility.css";

const ACNUFeasibility = () => {
  const navigate = useNavigate();
  const uuid = useParams().projectId;
  const { project, loading: loadingProject } = useProject(uuid, navigate);
  const { brandData, loading: loadingBrand } = useBrandAnalysis(uuid, navigate);
  
  useEffect(() => {
    // if no uuid, redirect to companies page
    if (!uuid) {
      navigate("/companies");
      console.error("Redirecting due to missing company or project");
      return;
    }
  }, [uuid, navigate]);

  if (loadingProject || loadingBrand) {
    return <Loading />;
  }

  if (!brandData || Object.keys(brandData).length === 0) {
    return <p>No brand data available.</p>;
  }

  if(!project || Object.keys(project).length === 0) {
    return <p>No project data available</p>
  }

  return (
    <div className='acnu-feasibility'>
      <section className="general">
        <Breadcrumbs project={project} currentPage="ACNU Feasibility" />

        <div className="page-header">
          <div className="content">
            <div className="flex flex-col md:flex-row md:gap-4 md:items-center col-span-1">
              <div className="grid grid-rows-2">
                <div className="flex gap-4 items-center">
                  <h1 className="grad">{project.name}</h1>
                  <Link to={`/project-edit`} state={{ project }}>
                    <FontAwesomeIcon icon={faEdit} />
                  </Link>

                  <RefreshButton
                    refresh_property="project"
                    project={project}
                    project_eligible_for_analysis={
                      project.eligible_for_analysis
                    }
                  />
                </div>

                {project.url && (
                  <a href={project.url} target="_blank" rel="noreferrer">
                    {project.url}
                    <FontAwesomeIcon
                      className="ml-2"
                      icon={faUpRightFromSquare}
                      size="xs"
                    />
                  </a>
                )}
              </div>

              <div className="acnu-section">
                {uuid === "e8d32602-0897-44a4-a14a-5152c1d44fdc" ? (
                  <Link
                    to={`/project/${uuid}/acnu_dashboard`}
                    state={{ project }}
                  >
                    ACNU Dashboard
                    <FontAwesomeIcon className="mr-2" icon={faChartPie} />
                  </Link>
                ) : (
                  <button disabled>
                    ACNU Dashboard
                    <FontAwesomeIcon className="mr-2" icon={faChartPie} />
                  </button>
                )}
              </div>
            </div>

            <ACNUInfo project={project} />
          </div>
        </div>
      </section>

      <section className="section-bg-light dark:section-bg-dark p-4 space-y-4">
        <div className="flex justify-between gap-4">
          <div>
            <h1>ACNU Forecast</h1>
            <p className="mb-2">The following story projects the opportunity associated with converting this medication to an OTC via an ACNU</p>
          </div>
          <ChartSettings project={ project } />
        </div>

        <ForecastCharts>
          <div>
            <ConditionOpportunity data={ project?.condition_analysis } />
          </div>

          {
            project.condition_demographics && project.condition_demographics?.data.length > 0 && <div>
              <DemographicOpportunity data={ project?.condition_analysis } />
            </div>
          }

          <div>
            <SearchOpportunity universe={ project?.keyword_universe } condition_analysis={ project.condition_analysis } />
          </div>

          <div>
            <SearchSpendRec data={ project?.spending_recs } />
          </div>

          <div>
            <ConversionFunnel data={ project?.keyword_universe } />
          </div>

          <div>
            <AnnualForecast data={ project?.keyword_universe } />
          </div>
        </ForecastCharts>
      </section>

      <section>
        <h2>Forecast by Category</h2>

        <div className="section-bg-light dark:section-bg-dark p-4 space-y-4">
          <div className="chart forecast space-y-4">
            <ForecastCharts>
              <Branded data={project?.keyword_universe} />
              <Condition data={project?.keyword_universe} />
            </ForecastCharts>
          </div>

          <ForecastTable data={project?.keyword_universe} />
        </div>
      </section>

      <section className="diagnosis-requirements">
        <h2 className="mb-2">Diagnosis Requirements</h2>

        <div className="info-container">
        {project?.condition_analysis  
          ? <Markdown disallowedElements={['code', 'pre']}>{ project?.condition_analysis.basic_diagnosis_criteria }</Markdown>
          : <p className="text-center px-0 py-8 md:p-8">
              No diagnosis criteria available.
            </p>}  
        </div>
      </section>

      <section className="safety-tolerability">
        <div className="mb-2">
          <h2>Safety and Tolerability</h2>
        </div>

        <div className="info-container">
          <Warnings project={project} />

          <div className="text-summary">
            { brandData && brandData.safety_tolerability_guide 
              ? <Markdown disallowedElements={['code', 'pre']}>{brandData.safety_tolerability_guide}</Markdown>
              : <p className="text-center px-0 py-8 md:p-8">"No safety and tolerability guide available.</p>
            }
          </div>

          <div className="row-start-3 md:col-start-5 md:row-start-1 md:row-span-2 md:col-span-2 section-bg-light dark:section-bg-dark">
            { brandData?.side_effect_plot && <TolerabilityChart project={ project } data={ brandData.side_effect_plot } /> }
          </div>
        </div>
      </section>

      <section className="stop-use">
        <h2 className="mb-2">Stopping Use, Dependence and Withdrawal</h2>

        <div className="info-container">
          {brandData && brandData.stop_usage_guide ? (
            <Markdown disallowedElements={['code', 'pre']}>{brandData.stop_usage_guide}</Markdown>
            ) : (
              <p className="text-center px-0 py-8 md:p-8">
                No stop usage data available.
              </p>
            )}
        </div>

        <div className="chart-container">
          {
            brandData?.stop_usage_activity &&
              <StopUsageChart data={brandData?.stop_usage_activity} />
          }
        </div>
      </section>
    </div>
  );
}

export default ACNUFeasibility;