import { useEffect, useState } from "react";

import Highcharts from "highcharts";
import ChartDashboard from "../../../../components/ChartDashboard/ChartDashboard.jsx";

import { useTheme } from "../../../../utils/ThemeManager.jsx";
import { getChartColors } from "../../../../utils/chartUtils.js";

import { formatCurrency } from "../../../../utils/tableUtils.js";

import "./Category.css";

Highcharts.themeDark = {
  colors: getChartColors('dark'),
};

Highcharts.themeLight = {
  colors: getChartColors('light'),
}

Highcharts.setOptions({
	plotOptions: {
		series: {
			animation: false
		}
	}
});

const categories = ['Uncategorized - Condition', 'Lifestyle', 'Symptoms', 'Diagnosis', 'Treatment', 'Uncategorized - Branded', 'Side Effects', 'OTC'];

// data: project.keyword_universe
const Category = ({ data }) => {
  const { theme } = useTheme();

  useEffect(() => {
    theme === 'dark'
      ? Highcharts.setOptions(Highcharts.themeDark)
      : Highcharts.setOptions(Highcharts.themeLight)
  }, [theme]);

  if (!data || !Object.keys(data).length) {
    return <p className="min-h-[550px] px-4 py-8 font-bold grid place-content-center">Estimated spend by category data is not available.</p>
  };

  return <ChartDashboard config={chartOptions(data, theme)} async={true} id="estimated-spend-category-bar" />;
};

export default Category;

const chartOptions = (data, theme) => {
  // stacked bar chart
  const universeBar = Array.from({ length: 12 }, (_, monthIndex) => {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return [
      monthNames[monthIndex],
      ...categories.map(category => data?.[category]?.monthly_cost_trend?.[monthIndex] || 0)
    ];
  });
  
  // pie chart
  const totalCost = categories.reduce((sum, category) => {
    return sum + (data[category]?.estimated_total_cost || 0);
  }, 0);

  const universePie = categories.map(category => {
    const cost = data[category]?.estimated_total_cost || 0;
    
    return {
      name: category.replace('Uncategorized - ', ''), 
      y: (cost / totalCost) * 100, 
      visible: cost > 1
    };
  });

  return {
    dataPool: {
      connectors: [
        {
        id: 'estimated-spend-category-bar',
        type: 'JSON',
        options: {
          firstRowAsNames: false,
          columnNames: ['Month', "Condition", "Lifestyle", "Symptoms", "Diagnosis", "Treatment", "Branded", "Side Effects", "OTC"],
          data: universeBar,
        }
      },
      {
        id: 'estimated-spend-category-pie',
        type: 'JSON',
        options: {
          firstRowAsNames: false,
          columnNames: ['name', 'y', 'visible'],
          data: universePie,

        }
      },
    ]
    },
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: "estimated-spend-category-pie-cell",
                  responsive: {
                    small: {
                      width: "100%"
                    },
                    medium: {
                      width: "40%"
                    },
                    large: {
                      width: "33.3%"
                    },
                  },
                },
                {
                  id: "estimated-spend-category-bar-cell",
                  responsive: {
                    small: {
                      width: "100%"
                    },
                    medium: {
                      width: "60%"
                    },
                    large: {
                      width: "66.6%"
                    }
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: "estimated-spend-category-bar-cell",
        sync: {
          visibility: true,
          highlight: true,
          extremes: true,
          crossfilter: true,
        },
        connector: {
          id: 'estimated-spend-category-bar',
          columnAssignment: [
            {
              seriesId: 'Branded',
              data: ['Month', "Lifestyle", "Symptoms", "Diagnosis", "Treatment", "Side Effects", "OTC"]
            },
        ]
        },
        chartOptions: {
          chart: {
            type: "column",
            height: 550,
          },
          sync: {
            highlight: true
          },
          title: {
            text: null,
          },
          xAxis: {
            categories: data['Grand Total'].search_volume_months,
          },
          yAxis: {
            title: {
              text: null
            },
            min: 0,
            labels: {
              formatter: function () {
                // if millions, divide by 1,000,000 and add 'M'
                // if thousands, divide by 1,000 and add 'K'
                if (this.value >= 1000000) return `${this.value / 1000000}M`;

                return this.value >= 1000 ? `${this.value / 1000}K` : this.value;

              },
              style: {
                fontSize: "14px", 
                fontWeight: "normal",
                color: 'contrast'
              }
            },
            stackLabels: {
              enabled: true,
              formatter: function () {
                // dollar sign and 2 decimal places
                return formatCurrency(this.total, 0);
              },
              style: {
                fontSize: "14px", 
                fontWeight: "normal",
                color: 'contrast'
              }
            },
          },
          legend: {
            reversed: false,
            align: "center",
            verticalAlign: "top",
          },

          tooltip: {
            formatter: function () {
                const colors = Highcharts.getOptions().colors;
                const colorIndex = this.series.colorIndex || this.point.colorIndex || 0;
                const color = colors[colorIndex];
                const coloredDot = `<span style="color:${color};">● </span>`;
                const formattedY = Highcharts.numberFormat(this.point.y, 0);
                // const formattedStackTotal = Highcharts.numberFormat(this.point.stackTotal, 0);
                const formattedPercentage = Highcharts.numberFormat(this.point.percentage, 1);
        
                // return `
                //     ${coloredDot}${this.series.name}: ${formattedY} (${formattedPercentage}%)
                //     <br/>${coloredDot}Total: ${formattedStackTotal}
                // `;
        
                return `${coloredDot}${this.series.name}: ${formatCurrency(this.point.y, 0)} (${formattedPercentage}%)`;
            }
        },
          plotOptions: {
            series: {
              stacking: "normal",
            },
          },
        },
      },
      {
        type: "Highcharts",
        cell: `estimated-spend-category-pie-cell`,
        chartOptions: {
          chart: {
            styledMode: false,
            type: "pie",
            height: 550,
          },
          title: {
            text: "Estimated Total Cost by Category",
            style: {
              color: 'contrast'
            }
          },
          tooltip: {
            enabled: true,
            stickOnContact: true,
            formatter: function () {
              const colorDot = `<span style="color:${this.point.color};">●</span>`;
              return `${colorDot} <b>${this.point.name}</b>: ${Highcharts.numberFormat(this.point.percentage, 1)}%`;
            },
            style: {
              color: 'contrast',
              fontSize: "0.8rem",
            },
          },
          subtitle: {
            text: "",
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: "pointer",
              dataLabels: [
                {
                  enabled: true,
                  // format: "<b>{point.name}</b>",
                  formatter: function () {
                    if (this.point.visible && this.percentage > 0.5) {
                      return this.point.visible ? this.point.name : null;
                    }
                  },
                  style: {
                    color: theme === "dark" ? "white" : "black",
                  },
                },
                {
                  distance: -40,
                  enabled: true,
                  formatter: function () {
                    if (this.point.visible && this.percentage > 0.5) {
                      return `${Highcharts.numberFormat(this.point.percentage, 1)}%`;
                    }
                  },
                  style: {
                    color: theme === "dark" ? "black" : "white",
                    fontSize: "12px",
                  },
                },
              ],
            },
          },
          series: [
            {
              name: "Percentage",
              type: "pie",
              data: universePie,
            },
          ],
        },
      },
    ],
  };
};