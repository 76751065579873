// react
import { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";

// hooks
import useCompanyPolygon from "../../hooks/useCompanyPolygon";

// axios
import { $axios } from "../../services/axiosInstance";

// components
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Loading from "../../components/Loading/Loading";
import CompanyLogo from "./CompanyLogo";
import CompanyChart from "./CompanyChart";
import NewsFeed from "./NewsFeed";
import ProjectFilter from "../../components/ProjectFilter/ProjectFilter";
import ProjectList from "../../components/ProjectList/ProjectList";
// context
import { useAppContext } from "../../context/AppContext";

// styles
import "./Company.css";

const Company = () => {
  const navigate = useNavigate();
  const { updatingProjects, updatedProjects, projectStatusError } =
    useAppContext();

  const [company, setCompany] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const uuid = useParams().companyId;
  const [polygonData, setPolygonData] = useCompanyPolygon(company?.ticker);
  const [selectedDifficulties, setSelectedDifficulties] = useState({
    lowest: true,
    low: true,
    moderate: true,
    high: true,
    highest: true,
  });

  // Function to handle toggle changes
  const handleToggle = (difficulty) => {
    setSelectedDifficulties((prev) => ({
      ...prev,
      [difficulty]: !prev[difficulty],
    }));
  };

  useEffect(() => {
    setLoading(true);

    // if no uuid, redirect to companies page
    if (!uuid) {
      navigate("/companies");
      return;
    }

    $axios
      .get(`/company/company/${uuid}/`)
      .then((response) => {
        setCompany(response.data);

        setLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          setError("Company not found");
        } else {
          setError("Error fetching company");
        }

        setLoading(false);
      });
  }, [uuid, navigate]);

  if (error) {
    return (
      <div className="company-page">
        <section className="info">
          <div className="page-header">
            <h1>{error}</h1>
          </div>

          <p className="py-40 text-center">
            Try going back to the{" "}
            <Link
              className="bold text-purple-600 hover:underline"
              to="/companies"
            >
              Companies
            </Link>{" "}
            page.
          </p>
        </section>
      </div>
    );
  }

  return (
    <div className="company-page">
      <section className="info">
        <Breadcrumbs company={company} />

        <div className="page-header">
          {company.logo_url ? (
            <CompanyLogo src={company.logo_url} alt={company.name} />
          ) : (
            <h1>{company.name}</h1>
          )}
        </div>

        <div className="company-description-container">
          { loading 
            ? null 
            : polygonData && polygonData.description 
            ? <p>{polygonData.description}</p>
            : <p>No description available</p>
          }
        </div>

        <div className="stock-info-container">
          <CompanyChart company={company} marketCap={polygonData?.market_cap} />
        </div>
      </section>

      <section className="projects">
        <div className="mb-2 flex gap-4 items-end justify-between">
          <h2>Projects</h2>

          <ProjectFilter
            selectedDifficulties={selectedDifficulties}
            handleToggle={handleToggle}
          />
        </div>

        <div className="section-bg-light dark:section-bg-dark">
          <ProjectList
            company={company}
            projects={company.brand_project}
            selectedDifficulties={selectedDifficulties}
            projectStatus={updatingProjects.filter(
              (s) => s.parent_company === company.uuid
            )}
          />
        </div>
      </section>

      <section className="news">
        <h2>Recent News</h2>

        <NewsFeed ticker={company.ticker} />
      </section>

      {loading && <Loading />}
    </div>
  );
};

export default Company;
