import { useEffect } from "react";

import Highcharts from "highcharts";
import ChartDashboard from "../../../components/ChartDashboard/ChartDashboard.jsx";

import { useTheme } from "../../../utils/ThemeManager.jsx";
import { getChartColors } from "../../../utils/chartUtils.js";

import { formatCurrency, formatPercent } from "../../../utils/tableUtils.js";

import './AnnualForecast.css';

Highcharts.themeDark = {
  colors: getChartColors('dark'),
};

Highcharts.themeLight = {
  colors: getChartColors('light'),
}

// data: project.keyword_universe
function AnnualForecast({ data }) {
  const branded = data?.["Brand Subtotal"] ?? {};
  const condition = data?.["Condition Subtotal"] ?? {};

  const { theme } = useTheme();

  const annualRevenue = [{
    name: 'Branded Revenue',
    data: [branded?.total_annual_revenue || 0]
  }, {
    name: 'Condition Revenue',
    data: [condition?.total_annual_revenue || 0] 
  }]

  const annualProfit = [{
    name: 'Annual Profit',
    data: [branded?.total_annual_profit || 0]
}, {
    name: 'Condition Profit',
    data: [condition?.total_annual_profit || 0]
}]

useEffect(() => {
  theme === 'dark'
    ? Highcharts.setOptions(Highcharts.themeDark)
    : Highcharts.setOptions(Highcharts.themeLight)
}, [theme]);

// if branded or condition is an empty object, return
if (Object.keys(branded).length === 0 || Object.keys(condition).length === 0) {
  return <>
    <div className="annual-forecast h-[550px] grid place-items-center">
      <p>Annual Forecast data is not available for this project.</p>
    </div>
  </>
}

return <div className="annual-forecast">
  <div className="content">
    <div className="col col-1">
      <h4 className="text-center font-bold p-2">Total Annual Revenue</h4>
      <p className="text-center text-2xl flex-1 grid place-items-center">{ formatCurrency(data['Grand Total'].total_annual_revenue) }</p>
      <h4 className="text-center font-bold p-2">Total Annual Profit</h4>
      <p className="text-center text-2xl flex-1 grid place-items-center">{ formatCurrency(data['Grand Total'].total_annual_profit) }</p>
      <h4 className="text-center font-bold p-2">Total Annual Profit Margin</h4>
      <p className="text-center text-2xl flex-1 grid place-items-center">{ formatPercent(data['Grand Total'].annual_profit_margin, 0) }</p>
    </div>
    <div className="col col-2">
      <ChartDashboard config={chartOptions(annualRevenue, 'revenue', 'Annual Revenue', theme)} async={true} id="revenue-container" />
    </div>
    <div className="col col-3">
      <ChartDashboard config={chartOptions(annualProfit, 'profit', 'Annual Profit', theme)} async={true} id="profit-container" />
    </div>
  </div>
</div>
} 

export default AnnualForecast;

const chartOptions = (data, name, title, theme) => {
  const contrast = theme === 'dark' ? '#fff' : '#000';

  return {
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: `${name}-keywords-cell`,
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: `${name}-keywords-cell`,
        chartOptions: {
          chart: {
              styledMode: false,
              type: 'column',
              height: 550,
              marginBottom: 100 // Adjust this value as needed
          },
          title: {
              text: title,
              align: 'center',
              style: {
                color: contrast
              }
          },
          xAxis: {
              categories: [`${title}`],
              labels: {
              style: {
                color: contrast,
                fontSize: '12px', // Increase font size for x-axis labels
              }
            }
          },
          yAxis: {
              min: 0,
              title: {
                  text: `${title} ($)`,
                  style: {
                    color: contrast,
                  }
              },
              labels: {
                formatter: function () {
                    return Highcharts.numberFormat(this.value / 1000000, 0) + 'M';
                },
                style: {
                  color: contrast,
                }
              },
              stackLabels: {
                enabled: true,
                formatter: function () {
                  return (this.total / 1000000).toFixed(1) + 'M'; // Changed precision to 1 and added 'M'
                },
                style: {
                  fontSize: '16px', // Increase font size for stack labels
                  fontWeight: 'bold',
                  color: contrast
                }
              }
          },
          legend: {
            layout: 'horizontal',       // Display the legend in a horizontal layout
            align: 'center',            // Center the legend horizontally
            verticalAlign: 'bottom',    // Position the legend at the bottom
            backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false,
            y: 0,
            itemStyle: {
              font: '10pt Trebuchet MS, Verdana, sans-serif bold',
              color: contrast
            }
          },
          tooltip: {
              headerFormat: null,
              // pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
              pointFormatter: function () {
                return `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${Highcharts.numberFormat(this.y, 0, '.', ',')}</b><br/>`;
              },
              style: {
                color: contrast
              }
          },
          plotOptions: {
            series: {
              animation: false,
            },
            column: {
              stacking: 'normal',
              dataLabels: {
                enabled: true,
                formatter: function () {
                  return (this.y / 1000000).toFixed(1) + 'M'
                },
                style: {
                  fontSize: '16px',
                  fontWeight: 'bold',
                  color: contrast,
                  textOutline: 'none'
                }
              }
            }
          },
          series: data
        },
      },

    ],
  };
};