import { useEffect, useState } from 'react';
import { Fragment } from 'react';

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import Highcharts from 'highcharts/highstock';

import addHighchartsMore from 'highcharts/highcharts-more';
import ChartDashboard from '../../../../components/ChartDashboard/ChartDashboard.jsx';

// styles
import "../Tables.css"

// theme
import { useTheme } from '../../../../utils/ThemeManager.jsx'
import { getChartColors } from '../../../../utils/chartUtils.js';

// table utils
import { 
  roundFloat,
  formatPercent, 
  formatCurrency,
  getSparklineOptions
} from '../../../../utils/tableUtils.js';

Highcharts.themeDark = {
  colors: getChartColors('dark'),
};

Highcharts.themeLight = {
  colors: getChartColors('light'),
}

// Highcharts.setOptions(Highcharts.theme);
addHighchartsMore(Highcharts);

// HeaderRow 
// the header row of the table
export const HeaderRow = () => {
  return <>
    <div className="grid-item header">Category</div>
    {/* <div className="grid-item header center">Difficulty*</div> */}
    {/* <div className="grid-item header center">Opportunity*</div> */}
    <div className="grid-item header center">Count</div>
    <div className="grid-item header center">Annual Volume</div>
    <div className="grid-item header center">Estimated CTR*</div>
    <div className="grid-item header center">Estimated Visits</div>
    <div className="grid-item header center">Average CPC*</div>
    <div className="grid-item header center">Estimated Total Cost</div>
    {/* <div className="grid-item header center">Estimated Monthly Cost</div> */}
    <div className="grid-item header center">Spark Chart</div>
  </>
}

// TableRow 
// is for each keyword category
// needs index for colored dot
export const TableRow = ({row, value, sparklineOptions, color, showBranded, showCondition}) => {
  // remove everything after the Uncategorized keyword
  const formattedRow = row.split(' - ')[0];

  const categoryMap = {
    "Uncategorized - Branded": 'Brand',
    OTC: 'Brand',
    "Branded Side Effects": 'Brand',
    "Uncategorized - Condition": 'Condition',
    Lifestyle: 'Condition',
    Symptoms: 'Condition',
    Diagnosis: 'Condition',
    Treatment: 'Condition',
  }

  if(categoryMap[row] === 'Brand' && !showBranded) {
    return null;
  }

  if(categoryMap[row] === 'Condition' && !showCondition) {
    return null;
  }

  return <Fragment key={`keyword-universe-${row}`}>
  <div className={`category-${formattedRow.replace(" ", "-")} grid-item data whitespace-nowrap space-x-2 gap-2`}>
    <svg width="12" height="12" fill={color}>
      <rect width="12" height="12" rx="6" ry="6" />
    </svg>
    {formattedRow}
  </div>

  {/* <div className={`difficulty grid-item data right`}>
    { formatFloat(value.keyword_difficulty_avg, 1) }
  </div> */}

  {/* <div className={`opportunity grid-item data right`}>
    { formatFloat(value.keyword_opportunity_score_avg, 1) }
  </div> */}

  <div className={`count grid-item data right`}>
    { roundFloat(value.keyword_count) }
  </div>

  <div className={`annual-volume grid-item data right`}>
    { roundFloat(value.search_volume_avg_annual_total) }
  </div>

  <div className={`estimated-ctr grid-item data right`}>
    { formatPercent(value.click_through_rate, 1) }
  </div>

  <div className="estimated-visits grid-item data right">
    { roundFloat(value.estimated_visits) }  
  </div>

  <div className="avg-cpc grid-item data right">
    { formatCurrency(value.cpc_final_avg) }  
  </div>

  <div className="estimated-cost-total grid-item data right">
    { formatCurrency(value.estimated_total_cost) }  
  </div>

  {/* <div className="estimated-cost-monthly grid-item data right">
    { formatCurrency(value.estimated_monthly_cost) }  
  </div> */}

  <div className={`spark grid-item data p-0`}>
    <ChartDashboard config={sparklineOptions} id={`sparkline-chart-${row}`} />
  </div>
</Fragment>
}

// Subtotal Row 
// is especially for the "Branded Subtotal" and "Condition Subtotal" rows
const SubtotalRow = ({ row, value, sparklineOptions, index, setBranded, showBranded, setCondition, showCondition }) => {

  const toggleSection = (e) => {
    const section = e.currentTarget.value;

    if(section === 'Brand') {
      setBranded(!showBranded);
    } else if(section === 'Condition') {
      setCondition(!showCondition);
    }
  }

  // row.split(' ')[0] === 'Brand' ? showBranded ? faChevronDown : faChevronRight : showCondition ? faChevronDown : faChevronRight

  return <Fragment key={`keyword-category-${row}`}>
    <div className={`category-${row.replace(' ', '-')} grid-item data subtotal whitespace-nowrap`}>
      <button className="flex items-center gap-2" onClick={toggleSection} value={row.split(' ')[0]}>
        <FontAwesomeIcon icon={faChevronRight} size="xs" className={`transform ${row.split(' ')[0] === 'Brand' ? showBranded ? 'rotate-90' : '' : showCondition ? 'rotate-90' : ''}`} />
        {row}
      </button>
    </div>

    {/* <div className="difficulty grid-item data subtotal right">
      { formatFloat(value.keyword_difficulty_final_avg, 1) }
    </div> */}

    {/* <div className="opportunity grid-item data subtotal right">
      { formatFloat(value.keyword_opportunity_score_final_avg, 1) }
    </div> */}

    <div className="count grid-item data subtotal right">
      { roundFloat(value.total_keyword_count) }
    </div>

    <div className="annual-volume grid-item data subtotal right">
      { roundFloat(value.search_volume_avg_annual_total) }
    </div>

    <div className="estimated-ctr grid-item data subtotal right">
      { formatPercent(value.ctr_final_avg, 1) }
    </div>

    <div className="estimated-visits grid-item data subtotal right">
      { roundFloat(value.total_estimated_visits) }
    </div>

    <div className="avg-cpc grid-item data subtotal right">
      { formatCurrency(value.cpc_final_avg) }
    </div>

    <div className="estimated-cost-total grid-item data subtotal right">
      { formatCurrency(value.estimated_final_annual_cost) }
    </div>

    {/* <div className="estimated-cost-monthly grid-item data subtotal right">
      { formatCurrency(value.estimated_final_monthly_cost) }
    </div> */}

    <div className="spark grid-item data subtotal p-0">
      <ChartDashboard config={sparklineOptions} id={`sparkline-chart-${index}`} />
    </div>
  </Fragment>
}

// Subtotal Row 
// is especially for the "Branded Subtotal" and "Condition Subtotal" rows
const TotalRow = ({ row, value, sparklineOptions, index }) => {
  return <Fragment key={`keyword-category-${row}`}>
    <div className="category-grand-total grid-item data total whitespace-nowrap">
      {row}
    </div>

    {/* <div className="category-grand-total difficulty grid-item data total right">
      { formatFloat(value.keyword_difficulty_final_avg, 1) }
    </div> */}
{/* 
    <div className="category-grand-total opportunity grid-item data total right">
      { formatFloat(value.keyword_opportunity_score_final_avg, 1) }
    </div> */}

    <div className="category-grand-total count grid-item data total right">
      { roundFloat(value.total_keyword_count) }
    </div>

    <div className="category-grand-total annual-volume grid-item data total right">
      { roundFloat(value.search_volume_avg_annual_total) }
    </div>

    <div className="category-grand-total estimated-ctr grid-item data total right">
      { formatPercent(value.ctr_final_avg, 1) }
    </div>

    <div className="category-grand-total estimated-visits grid-item data total right">
      { roundFloat(value.total_estimated_visits) }
    </div>

    <div className="category-grand-total avg-cpc grid-item data total right">
      { formatCurrency(value.cpc_final_avg) }
    </div>

    <div className="category-grand-total estimated-cost-total grid-item data total right">
      { formatCurrency(value.estimated_final_annual_cost) }
    </div>

    {/* <div className="category-grand-total estimated-cost-monthly grid-item data total right">
      { formatCurrency(value.estimated_final_monthly_cost) }
    </div> */}

    <div className="category-grand-total spark grid-item data total p-0">
      <ChartDashboard config={sparklineOptions} id={`sparkline-chart-${index}`} />
    </div>
  </Fragment>
}

// TableRows 
// the main component that maps over the data object and returns a TableRow or SubtotalRow component
// data: project.keyword_universe
const TableRows = ({ data }) => {
  const { theme } = useTheme();
  const [showBranded, setShowBranded] = useState(false);
  const [showCondition, setShowCondition] = useState(false);

  const keys = Object.keys(data);

  // maintain this key order
  const keyOrder = {
    "Condition Subtotal": 0,
    "Uncategorized - Condition": 1,
    Lifestyle: 2,
    Symptoms: 3,
    Diagnosis: 4,
    Treatment: 5,
    "Brand Subtotal": 6,
    "Uncategorized - Branded": 7,
    "Branded Side Effects": 8,
    OTC: 9,
    "Grand Total": 10,
  };
  const filteredKeys = keys.filter(key => keyOrder.hasOwnProperty(key));
  const sortedKeys = filteredKeys.sort((a, b) => keyOrder[a] - keyOrder[b]);

  const colors = getChartColors(theme);
  const colorMap = {
    "Brand Subtotal": theme === 'dark' ? '#ffffff' : '#555555',
    "Uncategorized - Branded": colors[5],
    OTC: colors[7],
    "Branded Side Effects": colors[6],
    "Uncategorized - Condition": colors[0],
    "Condition Subtotal": theme === 'dark' ? '#ffffff' : '#555555',
    Lifestyle: colors[1],
    Symptoms: colors[2],
    Diagnosis: colors[3],
    Treatment: colors[4],
    "Grand Total": theme === 'dark' ? '#ffffff' : '#555555',
  }

  useEffect(() => {
    theme === 'dark'
      ? Highcharts.setOptions(Highcharts.themeDark)
      : Highcharts.setOptions(Highcharts.themeLight)
  }, [theme]);

  // map over the keys and return a table row for each
  return sortedKeys.map((key, index) => {
    const sparklineOptions = getSparklineOptions(key, data[key].search_volume_monthly_totals, theme, colorMap[key]);

    // if the key is a subtotal row, render the SubtotalRow component
    if(key.includes('Subtotal')) {
      return <SubtotalRow
        row={key}
        value={data[key]}
        sparklineOptions={sparklineOptions}
        index={index}
        key={key}
        setBranded={setShowBranded}
        setCondition={setShowCondition}
        showBranded={showBranded}
        showCondition={showCondition}
      />
    }

    if(key === 'Grand Total') {
      return <TotalRow
        row={key}
        value={data[key]}
        sparklineOptions={sparklineOptions}
        index={index}
        key={key}
      />
    }

    // otherwise, render the TableRow component
    return <TableRow
      row={key}
      value={data[key]}
      sparklineOptions={sparklineOptions}
      key={key}
      color={colorMap[key]}
      showBranded={showBranded}
      showCondition={showCondition}
    />
  })
}

// data: project.keyword_universe
const UniverseTable = ({ data }) => {
  return <>
    <div className="grid-container universe-category" role="table">
      <HeaderRow />
      <TableRows data={data} />
    </div>
    <div className='text-xs col-span-full flex gap-x-0.5 items-center'>
      <div className='text-xl translate-y-[5px]'>*</div> &mdash; Subtotal values listed for these columns are the sums across given categories; grand total values listed are final averages across all categories
    </div>
  </>
}

export default UniverseTable;