// react
import { useEffect, useState } from "react";

// context
import { useAppContext } from "../../context/AppContext";

import ProjectDifficultyIcon from "../../components/ProjectList/ProjectDifficultyIcon";
import RefreshButton from "../../components/RefreshButton/RefreshButton";

// styles
import "./ProjectStatus.css";

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const ProjectStatus = () => {
  const { updatingProjects, updatedProjects, projectStatusError, semrushAvailable, unitsAvailable } = useAppContext();
  const [queuedProjects, setQueuedProjects] = useState([]);
  const [inProgressProjects, setInProgressProjects] = useState([]);
  const [successProjects, setSuccessProjects] = useState([]);
  const [failedProjects, setFailedProjects] = useState([]);
  const [semrushStatus, setSemrushStatus] = useState('unavailable');

  useEffect(() => {
    // Set the SEMrush status
    setSemrushStatus(semrushAvailable ? 'available' : 'unavailable');

    // Filter the updatingProjects array by status
    const queued = updatingProjects.filter(project => project.processing_status === 'Queued');
    setQueuedProjects(queued);
    
    const inProgress = updatingProjects.filter(project => project.processing_status === 'In Progress');
    setInProgressProjects(inProgress);
    
    const success = updatedProjects.filter(project => project.processing_status === 'Success');
    setSuccessProjects(success);
    
    const failed = updatedProjects.filter(project => project.processing_status === 'Failure');
    setFailedProjects(failed);
  }, [updatingProjects, updatedProjects]); // Dependency on updatingProjects

  return <div className="project-status-page">
    <div className="page-header">
      <div className="col-1">
      <h1 className="grad">Project Status</h1>
      <p>View the status of all projects.</p>
      </div>

      <div className="col-2">
        <div className="semrush-status">
          <h2 className="sr-only">SEM Rush Status</h2>
          <div className="status-item">
            <h3>Available Units</h3>
            <p>{ unitsAvailable
              ? unitsAvailable.toLocaleString()
              : 0
            }</p>
          </div>
          <div className="status-item ">
            <h3>SEMRush Status</h3>
            <p className={semrushStatus}>{semrushStatus}</p>
          </div>
        </div>
      </div>
    </div>


    <section className="queued-projects">
      <h2>Queued</h2>
      <p className="mb-2">These projects are waiting to be processed.</p>

      <div className="table-container overflow-x-scroll w-full">
        <table className="project-table status">
          <thead>
            <tr>
              <th>Project</th>
              <th>Company</th>
              <th>ACNU Difficulty</th>
              <th>Run Initiated</th>
              <th>Last Run</th>
            </tr>
          </thead>
          <tbody>
            {
              queuedProjects.length ? queuedProjects.map((project, index) => {
                return <tr key={`queued-${project.id}-${index}`}>
                  <td>
                    <a 
                      href={`/project/${project.uuid}`}
                      target="_blank"
                      rel="noreferrer"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(`/project/${project.uuid}`, '_blank');
                      }}
                    >
                      {project.name}
                    </a>
                  </td>
                  <td>
                  <a 
                      href={`/company/${project.parent_company}`}
                      target="_blank"
                      rel="noreferrer"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(`/company/${project.parent_company}`, '_blank');
                      }}
                    >
                      {project.parent_company_name}
                    </a>
                  </td>
                  <td><ProjectDifficultyIcon acnu_difficulty={project.acnu_difficulty} /></td>
                  <td>{formatDate(project.run_initiated)}</td>
                  <td>{formatDate(project.last_run)}</td>
                </tr>
              }) : <tr><td colSpan="5" className="no-data text-center">No projects are currently queued.</td></tr>
            }
          </tbody>
        </table>
      </div>
    </section>

    <section className="in-progress-projects">
      <h2>In Progress</h2>
      <p className="mb-2">These projects are currently being processed.</p>

      <div className="table-container overflow-x-scroll w-full">
        <table className="project-table status">
          <thead>
            <tr>
              <th>Project</th>
              <th>Company</th>
              <th>ACNU Difficulty</th>
              <th>Run Initiated</th>
              <th>Last Run</th>
            </tr>
          </thead>
          <tbody>
            {
              inProgressProjects.length ? inProgressProjects.map(project => {
                return <tr key={`progress-${project.id}`}>
                  <td>
                    <a 
                      href={`/project/${project.uuid}`}
                      target="_blank"
                      rel="noreferrer"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(`/project/${project.uuid}`, '_blank');
                      }}
                    >
                      {project.name}
                    </a>
                  </td>
                  <td>
                  <a 
                      href={`/company/${project.parent_company}`}
                      target="_blank"
                      rel="noreferrer"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(`/company/${project.parent_company}`, '_blank');
                      }}
                    >
                      {project.parent_company_name}
                    </a>
                  </td>
                  <td><ProjectDifficultyIcon acnu_difficulty={project.acnu_difficulty} /></td>
                  <td>{formatDate(project.run_initiated)}</td>
                  <td>{formatDate(project.last_run)}</td>
                </tr>
              }) : <tr><td colSpan="5" className="no-data text-center">No projects are currently being processed.</td></tr>
            }
          </tbody>
        </table>
      </div>
    </section>

    <section className="completed-projects">
      <h2>Recently Completed</h2>
      <p className="mb-2">These projects have recently been processed.</p>

      <div className="table-container overflow-x-scroll w-full">
        <table className="project-table status complete">
          <thead>
            <tr>
              <th className="align-middle">Project</th>
              <th className="align-middle">Company</th>
              <th className="align-middle">ACNU Difficulty</th>
              <th className="align-middle text-right">Completion Time (Minutes)</th>
              <th className="align-middle text-center">Last Run</th>
              <th className="align-middle text-right">Units Used</th>
              <th className="align-middle text-right">Cost</th>
            </tr>
          </thead>
          <tbody>
            {
              successProjects.length ? successProjects.map((project, index) => {
                return <tr key={`complete-${project.id}-${index}`}>
                  <td>
                    <a 
                      href={`/project/${project.uuid}`}
                      target="_blank"
                      rel="noreferrer"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(`/project/${project.uuid}`, '_blank');
                      }}
                    >
                      {project.name}
                    </a>

                    <RefreshButton 
                      refresh_property="project"
                      project={project}
                    />
                  </td>
                  <td>
                  <a 
                      href={`/company/${project.parent_company}`}
                      target="_blank"
                      rel="noreferrer"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(`/company/${project.parent_company}`, '_blank');
                      }}
                    >
                      {project.parent_company_name}
                    </a>
                  </td>
                  <td><ProjectDifficultyIcon acnu_difficulty={project.acnu_difficulty} /></td>
                  <td className="justify-end">{
                    project.completion_time !== 'N/A' 
                      ? project.completion_time.toFixed(2)
                      : project.completion_time
                  }</td>
                  <td>{formatDate(project.last_run)}</td>
                  <td className="justify-end">{project.semrush_units_used.toLocaleString()}</td>
                  <td className="justify-end">${project.latest_semrush_cost.toFixed(2)}</td>
                </tr>
              }) : <tr><td colSpan="5" className="no-data text-center">No projects have been processed.</td></tr>
            }
          </tbody>
        </table>
      </div>
    </section>


    <section className="in-progress-projects">
      <h2>Failed</h2>
      <p className="mb-2">These projects have failed to process.</p>
      
      <div className="table-container overflow-x-scroll w-full">
        <table className="project-table status failure">
          <thead>
            <tr>
              <th>Project</th>
              <th>Company</th>
              <th>ACNU Difficulty</th>
              <th className="text-right">Completion Time (Minutes)</th>
              <th className="text-center">Last Run</th>
            </tr>
          </thead>
          <tbody>
            {
              failedProjects.length ? failedProjects.map((project, index) => {
                return <tr key={`failure-${project.id}-${index}`}>
                  <td>

                    <a 
                      href={`/project/${project.uuid}`}
                      target="_blank"
                      rel="noreferrer"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(`/project/${project.uuid}`, '_blank');
                      }}
                    >
                      {project.name}
                    </a>
                    
                    <RefreshButton 
                      refresh_property="project"
                      project={project}
                    />

                  </td>
                  <td>
                  <a 
                      href={`/company/${project.parent_company}`}
                      target="_blank"
                      rel="noreferrer"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(`/company/${project.parent_company}`, '_blank');
                      }}
                    >
                      {project.parent_company_name}
                    </a>
                  </td>
                  <td><ProjectDifficultyIcon acnu_difficulty={project.acnu_difficulty} /></td>
                  <td className="justify-end">{project.completion_time !== 'N/A' 
                    ? project.completion_time.toFixed(2) 
                    : project.completion_time
                  }</td>
                  <td>{formatDate(project.last_run)}</td>
                </tr>
              }) : <tr><td colSpan="5" className="no-data text-center">No projects have failed to process.</td></tr>
            }
          </tbody>
        </table>
      </div>
    </section>

  </div>;
};

export default ProjectStatus;