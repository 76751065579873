import React, { useEffect } from 'react';
import * as Dashboards from '@highcharts/dashboards/dashboards';

import Highcharts from 'highcharts';
import highchartsExporting from 'highcharts/modules/exporting'; //module for exporting chart
import highchartsMore from 'highcharts/highcharts-more'; //needed for dumbbell chart and waterfall
import highchartsVenn from "highcharts/modules/venn"; //module for venn chart
import highchartsFunnel from "highcharts/modules/funnel"; //module for funnel chart
import highchartsSunburst from "highcharts/modules/sunburst";
import highchartsHeatmap from "highcharts/modules/heatmap";
import highchartsHistogram from "highcharts/modules/histogram-bellcurve.js"; //module for histogram chart
// import highchartsDumbbell from "highcharts/modules/dumbbell.js"; //module for dumbbell chart

import highchartsAccessibility from "highcharts/modules/accessibility";

import DataGrid from '@highcharts/dashboards/es-modules/DataGrid/DataGrid';
import DataGridPlugin from '@highcharts/dashboards/es-modules/Dashboards/Plugins/DataGridPlugin';


import HighchartsPlugin from '@highcharts/dashboards/es-modules/Dashboards/Plugins/HighchartsPlugin';

import { useTheme } from "../../utils/ThemeManager.jsx";

// Apply the theme
Highcharts.setOptions({
  colors: ['#058DC7', '#3a9397', '#ED561B', '#DDDF00', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4'],
  chart: {
      backgroundColor: {
          linearGradient: [0, 0, 500, 500],
          stops: [
              [0, 'rgb(255, 255, 255)'],
              [1, 'rgb(240, 240, 255)']
          ]
      },
  },
  title: {
      style: {
          color: '#000',
          font: 'bold 16px "Trebuchet MS", Verdana, sans-serif'
      }
  },
  subtitle: {
      style: {
          color: '#666666',
          font: 'bold 12px "Trebuchet MS", Verdana, sans-serif'
      }
  },
  legend: {
      itemStyle: {
          font: '9pt Trebuchet MS, Verdana, sans-serif',
          color: 'black'
      },
      itemHoverStyle:{
          color: 'gray'
      }
  },
  plotOptions: {
    pie: {
        dataLabels: {
            style: {
                color: 'contrast',
                fontSize: '110px',
                textOutline: 'none'
            }
        }
    }
  },
});

// highchartsExporting(Highcharts);
highchartsExporting(Highcharts);
highchartsVenn(Highcharts);
highchartsFunnel(Highcharts);
highchartsSunburst(Highcharts);
highchartsHeatmap(Highcharts);
highchartsHistogram(Highcharts);
// highchartsDumbbell(Highcharts);
highchartsMore(Highcharts);
highchartsAccessibility(Highcharts);

HighchartsPlugin.custom.connectHighcharts(Highcharts);
DataGridPlugin.custom.connectDataGrid(DataGrid);

Dashboards.PluginHandler.addPlugin(HighchartsPlugin);
Dashboards.PluginHandler.addPlugin(DataGridPlugin);

export default function ChartDashboard({ config, id = 'container', async = false }) {
  const { theme } = useTheme();
  useEffect(() => {
    Dashboards.board(id, config, async);
  }, [config, theme]);

  return <div id={id} className={`highcharts-${theme}`}></div>;
}